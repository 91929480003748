import React, { useReducer } from 'react';
import ACTION from './actionTypes';

export const AccountStore = React.createContext('');

const initialState = {
  bankTypes: [],
  bankTypesReq: {},
  bankAccountTypes: [],
  bankAccountTypesReq: {},
  userPayoutData: null,
  userPayoutDataReq: {},
  profileInputs: null,
  identityTypesReq: {},
  identityTypes: [],
  selectedOption: 0,
  userDataReq: {},
  userData: null,
  submitProfileReq: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.GET_BANK_TYPES_REQ: {
      return {
        ...state,
        bankTypesReq: action.req
      };
    }
    case ACTION.GET_BANK_ACCOUNT_TYPES_REQ: {
      return {
        ...state,
        bankAccountTypesReq: action.req
      };
    }
    case ACTION.USER_PAYOUT_DATA_REQ: {
      return {
        ...state,
        userPayoutDataReq: action.req
      };
    }
    case ACTION.GET_USER_DATA_REQ:
      return {
        ...state,
        userDataReq: action.req
      };
    case ACTION.SUBMIT_PROFILE_REQ:
      return {
        ...state,
        submitProfileReq: action.req
      };
    case ACTION.GET_IDENTITY_TYPES_REQ:
      return {
        ...state,
        identityTypesReq: action.req
      };
    case ACTION.BANK_TYPES:
      return {
        ...state,
        bankTypes: action.bankTypes
      };
    case ACTION.BANK_ACCOUNT_TYPES:
      return {
        ...state,
        bankAccountTypes: action.bankAccountTypes
      };
    case ACTION.USER_PAYOUT_DATA:
      return {
        ...state,
        userPayoutData: action.userPayoutData
      };
    case ACTION.IDENTITY_TYPES:
      return {
        ...state,
        identityTypes: action.identityTypes
      };
    case ACTION.USER_DATA:
      return {
        ...state,
        userData: action.userData
      };
    case ACTION.PROFILE_INPUTS:
      return {
        ...state,
        profileInputs: action.profileInputs
      };
    case ACTION.SELECTED_OPTION:
      return {
        ...state,
        selectedOption: action.id
      };
    default:
      return {
        ...state
      };
  }
};

export function AccountProvider(props) {
  return (
    <AccountStore.Provider value={useReducer(reducer, initialState)}>
      {props.children}
    </AccountStore.Provider>
  );
}
