import React from 'react'
import styled from 'styled-components'
import HeadEvent from "../../HeadEvent/HeadEvent";
import { formatDate, DATE_PARSER } from '../../../helpers/helpers';
import DS from '../../../VisualStyles';

const Link = styled.div`
  color: ${DS.COLORS.TITLE.PRIMARY};
  cursor: pointer;
`;

const columns = [
  {
    allowFiltering: true,
    allowGrouping: true,
    allowSearch: true,
    caption: 'Evento',
    calculateCellValue: ({ event }) => `${event.name} - ${formatDate({ date: event.date, style: DATE_PARSER.LONG_DATE_TIME })}`,
    cellRender: ({ data }) => <HeadEvent event={data.event} />,
  },
  {
    caption: 'Ubicación',
    dataField: 'location.name'
  },
  {
    allowFiltering: false,
    alignment: 'center',
    caption: 'Sector',
    dataField: 'location.section.name'
  },
  {
    allowFiltering: false,
    alignment: 'center',
    caption: 'Fila',
    dataField: 'location.row'
  },
  {
    allowFiltering: false,
    alignment: 'center',
    caption: 'Cantidad',
    dataField: 'tickets',
    width: 80
  },
  {
    allowFiltering: false,
    allowGrouping: false,
    caption: 'A recibir',
    dataField: 'ticket_receive.amount',
    format: '$ #.##0,##',
    width: 100
  },
  {
    allowSearch: true,
    allowFiltering: false,
    caption: 'Código',
    dataField: 'code',
    cellRender: ({ data }) => <Link>{data.code}</Link>,
    width: 120
  },
  {
    caption: 'Recinto',
    dataField: 'event.venue.name'
  },
  {
    allowFiltering: false,
    alignment: 'center',
    caption: 'Fecha de venta',
    dataField: 'date_created',
    dataType: 'date',
    format: 'dd/MM/yyyy'
  }
];

export default columns;
