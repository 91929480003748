import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useFetch from '../../hooks/useFetch';
import { API_ROUTES, API_URL } from '../../config';
import { Select } from 'antd';

const { Option } = Select;

const ContentWrap = styled.div`
  display: block;
  flex: 1;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 30px;

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  margin-bottom: 15px;
`

const ReportsContainer = () => {
  const [reportCategories, setReportCategories] = useState([]);
  const [reportTypeReq, setReportTypeReq] = useState({});
  const [iframeUrl, setIframeUrl] = useState(null);

  const req = {
    url: `${API_URL}/${API_ROUTES.REPORTS}`
  };

  const [reportOptions] = useFetch(req);
  const [reportUrl] = useFetch(reportTypeReq);

  useEffect(() => {
    const { results } = reportOptions;

    if (results) {
      setReportCategories(results);
      const [firstItem] = results

      setReportTypeReq({
        url: `${API_URL}/${API_ROUTES.REPORTS}/${firstItem.value}/token`
      });
    }

  }, [reportOptions]);

  useEffect(() => {
    const { url } = reportUrl;
    setIframeUrl(url);
  }, [reportUrl]);

  const handleOnSelect = id => {
    setReportTypeReq({
      url: `${API_URL}/${API_ROUTES.REPORTS}/${id}/token`
    });
  };

  return (
    <ContentWrap key={iframeUrl}>
      {!!reportCategories.length && (
        <StyledSelect defaultValue={reportCategories[0].value} onSelect={handleOnSelect}>
          {reportCategories.map(({ id, value }) => (
            <Option key={[id, value].join('|')} value={id}>{value}</Option>
          ))}
        </StyledSelect>
      )}
      {iframeUrl && (
        <iframe
          allowFullScreen
          frameBorder="0"
          src={iframeUrl}
          style={{width: '100%', height: '600px'}}
          title="reporting"
        ></iframe>
      )}
    </ContentWrap>
  );
};

export default ReportsContainer;
