import React, { useReducer } from 'react';
import ACTION from './actionTypes';

export const AppStore = React.createContext('');

const initialState = {
  fetchErrors: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.FETCH_ERRORS:
      return {
        ...state,
        fetchErrors: action.fetchError
      };
    default:
      return {
        ...state
      };
  }
};

export function AppProvider(props) {
  return (
    <AppStore.Provider value={useReducer(reducer, initialState)}>
      {props.children}
    </AppStore.Provider>
  );
}
