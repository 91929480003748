const accountInputs = [
  {
    label: 'Nombre',
    name: 'first_name',
    type: 'text'
  },
  {
    label: 'Apellido',
    name: 'last_name',
    type: 'text'
  },
  {
    name: 'document',
    type: 'text'
  },
  {
    label: 'Dirección',
    name: 'address',
    type: 'text'
  },
  {
    label: 'Localidad',
    name: 'city',
    type: 'text'
  },
  {
    label: 'Provincia',
    name: 'state',
    type: 'text'
  },
  {
    label: 'Código Postal',
    name: 'zip_code',
    type: 'text'
  },
  {
    label: 'Teléfono',
    name: 'phone_number',
    type: 'number'
  }
];

export default accountInputs;
