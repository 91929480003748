import { useState } from 'react';

const useForm = () => {
  const [values, setValues] = useState({});

  // This function is explicit to the listing detail view.
  const addCodeValue = ({ code, name, value }) => {
    setValues(values => ({
      ...values,
      [code]: {
        ...values[code],
        [name]: {
          value: Number(value)
        }
      }
    }));
  };

  const addValue = ({ name, value }) => {
    setValues(values => ({
      ...values,
      [name]: value
    }));
  };

  const handleChange = (e, additionalValues) => {
    // e.persist();
    const { name, value } = e.currentTarget;

    if (additionalValues) {
      const { code, values } = additionalValues;

      if (code) {
        return addCodeValue({ code, name, value });
      }

      // Explicitly add values as an object
      if (values) {
        return addValue({ name, value: values });
      }
    }

    addValue({ name, value });
  };

  return {
    addCodeValue,
    addValue,
    handleChange,
    values
  };
};

export default useForm;
