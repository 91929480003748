import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import NotFound from '../../components/EmptyState/NotFound';
import useFetch from '../../hooks/useFetch';
import { API_ROUTES, API_URL } from '../../config';
import { formatDate, DATE_PARSER } from '../../helpers/helpers';
import NoImage from '../../components/EmptyState/NoImage';
import ACTION from '../../reducers/orders/actionTypes';
import { OrderStore } from '../../reducers/orders/ordersReducer';
import Loading from '../../components/Loading/Loading';
import OrderDetail from '../../components/OrderDetail/OrderDetail';

const STR_CONFIG = {
  NO_ORDER: 'Todavía no hay compras para mostrar.'
};

const ListWrap = styled.div`
  max-width: 1176px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
`;
const List = styled.div`
  width: 100%;
`;
const ListItem = styled.div`
  align-items: center;
  border: solid 1px #f3f4f8;
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 100px 1fr 130px;
  margin-bottom: 20px;
  padding: 10px 18px 10px 8px;
  position: relative;

  &:after {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.04), 0 0px 4px rgba(0, 0, 0, 0.04),
      0 0px 8px rgba(0, 0, 0, 0.04), 0 0px 16px rgba(0, 0, 0, 0.04),
      0 0px 32px rgba(0, 0, 0, 0.04), 0 0px 64px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.04), 0 0px 4px rgba(0, 0, 0, 0.04),
      0 0px 8px rgba(0, 0, 0, 0.04), 0 0px 16px rgba(0, 0, 0, 0.04),
      0 0px 32px rgba(0, 0, 0, 0.04), 0 0px 64px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    content: '';
    opacity: 0;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    transition: all 200ms;
  }

  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;
const EventInfo = styled.div`
  .event-name {
    color: #4d5359;
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
  .order-id {
    color: #a2a9b1;
    font-size: 11px;
  }
`;

const OrdersContainer = () => {
  const [state, dispatch] = useContext(OrderStore);
  const [orderList, setOrderList] = useState(null);
  const orderReq = { url: `${API_URL}/${API_ROUTES.ORDERS}` };
  const [orders] = useFetch(orderReq);

  useEffect(() => {
    const { results } = orders;
    setOrderList(results ? results : null);
  }, [orders]);

  const openOrderDetail = event => {
    dispatch({
      event,
      type: ACTION.ORDER_DETAIL
    });
  };

  return (
    <React.Fragment>
      {orderList && !state.orderDetail && (
        <ListWrap>
          <List>
            {orderList.map(order => {
              return (
                <ListItem key={order.code} onClick={() => openOrderDetail(order)}>
                  {order.event.image ? (
                    <img src={order.event.image} alt={order.event.name} />
                  ) : (
                    <NoImage />
                  )}
                  <EventInfo>
                    <div className="event-name">{order.event.name}</div>
                    <div className="event-date">
                      {formatDate({
                        date: order.event.date,
                        style: DATE_PARSER.DATE_YEAR_TIME
                      })}
                    </div>
                    <div className="event-venue">
                      {order.event.venue.name} - {order.event.venue.city}
                    </div>
                    <div className="order-id">{order.code}</div>
                  </EventInfo>
                  <Button type="primary" ghost>
                    Ver Detalle
                  </Button>
                </ListItem>
              );
            })}
          </List>
        </ListWrap>
      )}
      {state.orderDetail && (
        <OrderDetail
          order={state.orderDetail}
          onNavBack={() => dispatch({ type: ACTION.CLEAR_ORDER_DETAIL })}
        />
      )}
      {!orderList && <Loading fullSize />}
      {orderList && !orderList.length && <NotFound text={STR_CONFIG.NO_ORDER} />}
    </React.Fragment>
  );
};

export default OrdersContainer;
