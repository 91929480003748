import React, { useEffect, useContext } from 'react';
import useFetch from '../../hooks/useFetch';
import { ListingsStore } from '../../reducers/listings/listingsReducer';
import { API_URL, API_ROUTES } from '../../config';
import NewPublication from '../../components/Publication/NewPublication';
import { PublicationStore } from '../../reducers/publication/publicationReducer';
import ACTION from '../../reducers/publication/actionTypes';
import LISTING_ACTIONS from '../../reducers/listings/actionTypes';
import EditPublication from '../../components/Publication/EditPublication';
import publicationInputs from './publicationInputs';
import { message } from 'antd';
import { isEmptyObject, removeLettersFromId } from '../../helpers/helpers';

const PublicationContainer = ({ onClose }) => {
  const [state, dispatch] = useContext(PublicationStore);
  const [listingsState, listingsDispatcher] = useContext(ListingsStore);

  const { editListing, openPublicationDrawer } = listingsState;

  const [publicationConstraints] = useFetch(state.constraintsReqUrl);
  const [submitForm, { error, loading }] = useFetch(state.submitPublication);

  const onFormSubmit = ({ selectedEvent, submitData, editListing = {} }) => {
    const isEditing = !isEmptyObject(editListing);
    const { code } = editListing;

    const method = isEditing ? 'PUT' : 'POST';
    const url = isEditing
      ? `${API_URL}/${API_ROUTES.LISTINGS}/${code}`
      : `${API_URL}/${API_ROUTES.LISTINGS}`;

    dispatch({
      req: {
        body: submitData,
        options: { method },
        url
      },
      type: ACTION.SUBMIT_PUBLICATION
    });
  };

  useEffect(() => {
    const success = !isEmptyObject(submitForm);
    const isEditing = !isEmptyObject(editListing);

    if (success) {
      message.success(
        isEditing ? 'Entradas modificadas' : 'Tus entradas fueron publicadas.'
      );

      dispatch({
        type: ACTION.RESET_PUBLICATION_FLOW
      });

      const activeListingExists = listingsState.eventListings.some(
        ({ code }) => removeLettersFromId(code) === submitForm.event.id
      );

      if (!isEditing && !activeListingExists) {
        listingsDispatcher({
          listing: submitForm,
          type: LISTING_ACTIONS.ADD_NEW_LISTING
        });
      } else {
        listingsDispatcher({
          isEditing,
          listing: submitForm,
          type: LISTING_ACTIONS.UPDATE_LISTING
        });
      }

      onClose();
    }
    // eslint-disable-next-line
  }, [submitForm]);

  useEffect(() => {
    if (error && error.status === 500) {
      message.error('Hubo un error al crear la publicación.');
    }
  }, [error]);

  useEffect(() => {
    if (!openPublicationDrawer) {
      dispatch({
        type: ACTION.RESET_PUBLICATION_FLOW
      });
    }
    // eslint-disable-next-line
  }, [openPublicationDrawer]);

  useEffect(() => {
    const { selectedEvent } = state;
    if (!selectedEvent) return;
    const eventId = selectedEvent.id.toString().replace(/\D/g, '');

    dispatch({
      reqUrl: {
        url: `${API_URL}/events/${eventId}/constraints`
      },
      type: ACTION.CONSTRAINTS_REQ_URL
    });

    // eslint-disable-next-line
  }, [state.selectedEvent]);

  useEffect(() => {
    dispatch({
      constraints: publicationConstraints,
      type: ACTION.PUBLICATION_CONSTRAINTS
    });
    // eslint-disable-next-line
  }, [publicationConstraints]);

  useEffect(() => {
    const isEditing = Object.values(editListing).length > 0;

    if (isEditing) {
      const { event } = editListing;

      dispatch({
        event,
        type: ACTION.SELECTED_EVENT
      });

      const editForm = publicationInputs.map(input => {
        const { name } = input;
        const doesExist = name in editListing || name in editListing.location;

        if ((name === 'section' || name === 'row') && doesExist) {
          return { ...input, value: editListing.location[name] };
        }

        if (name === 'location') {
          return { ...input, value: editListing.location.id };
        }

        if (name === 'ticket_price' || name === 'ticket_receive') {
          return { ...input, value: editListing[name].amount };
        }

        return doesExist ? { ...input, value: editListing[name] } : null;
      });

      dispatch({
        editForm,
        type: ACTION.EDIT_FORM
      });
    }
  }, [editListing]);

  useEffect(() => {}, [state.submitPublication]);

  const isEditingEvent = !isEmptyObject(listingsState.editListing);

  const { selectedEvent } = state;

  return (
    <React.Fragment>
      <NewPublication
        isOpen={!isEditingEvent && openPublicationDrawer}
        loading={loading}
        onClose={onClose}
        onSubmit={submitData => onFormSubmit({ selectedEvent, submitData })}
      />

      <EditPublication
        listing={editListing}
        form={state.editForm}
        isOpen={isEditingEvent && openPublicationDrawer}
        loading={loading}
        onClose={onClose}
        onSubmit={submitData => onFormSubmit({ selectedEvent, submitData, editListing })}
      />
    </React.Fragment>
  );
};

export default PublicationContainer;
