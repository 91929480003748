import React, { useEffect, useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { ListingsStore } from '../../../reducers/listings/listingsReducer';
import { FormContext } from '../../../containers/listings/ListingDetailContainer';
import ActionIconsContainer from '../../../containers/listings/ActionIconsContainer';
import { Switch } from 'antd';
import ACTION from '../../../reducers/listings/actionTypes';
import { API_URL, API_ROUTES } from '../../../config';

const Input = styled.input`
  border-radius: 3px;
  border: solid 1px #ccc;
  padding: 5px 7px;
  width: 100%;
`;
const FlashAnimation = keyframes`
  to {
    opacity: 0.5;
  }
`;
const TextSkeleton = styled.div`
  animation: ${FlashAnimation} 1s alternate infinite ease-in-out;
  background-color: #bbe6ff;
  height: 15px;
  width: 70px;
`;

const LISTING_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

const ActiveSwitch = ({ code, status }) => {
  const [, dispatch] = useContext(ListingsStore);

  const active = status === LISTING_STATUS.INACTIVE ? false : true;
  const [checked, setChecked] = useState(active);

  const handleOnClick = () => {
    setChecked(!checked);
    dispatch({
      req: {
        body: {
          status: checked ? LISTING_STATUS.INACTIVE : LISTING_STATUS.ACTIVE
        },
        options: {
          method: 'PATCH'
        },
        url: `${API_URL}/${API_ROUTES.LISTINGS}/${code}`
      },
      type: checked ? ACTION.REQ_PAUSE_LISTING : ACTION.REQ_RESUME_LISTING
    });
  };

  return (
    <Switch
      defaultChecked={active}
      checked={checked}
      onClick={() => handleOnClick()}
      size="small"
    />
  );
};

const EventId = ({ data: { code, fetchingId } }) =>
  fetchingId ? <TextSkeleton /> : code;

const PriceInput = ({ data, name }) => {
  const [, dispatch] = useContext(ListingsStore);
  const { addCodeValue, values, handleChange } = useContext(FormContext);
  const [state] = useContext(ListingsStore);
  const [currentEvent, setCurrentEvent] = useState();

  const {
    event: { id: eventId }
  } = data;

  useEffect(() => {
    setCurrentEvent(state.activeListings.find(({ code }) => code === `E${eventId}`));

    addCodeValue({
      code: data.code,
      name,
      value: data[name].amount
    });
  }, []);

  // We first update the current input value using the handleChange function
  // Then, we find the opposite input to change its value with the given coefficient
  const handleValue = (e, location, values) => {
    const currentInput = e.currentTarget.name;
    const locationId = Number(location);

    const { ticket_price_coefficient: coefficient } = currentEvent.constraints.find(
      ({ id }) => id === locationId
    );

    const valueToNumber = Number(e.currentTarget.value);

    const oppositeValue =
      currentInput === 'ticket_price'
        ? (valueToNumber * coefficient).toFixed(2)
        : (valueToNumber / coefficient).toFixed(2);

    if (e.type === 'blur') {
      const value = currentInput === 'ticket_receive' ? oppositeValue : valueToNumber;

      const req = {
        body: {
          ticket_price: {
            amount: value
          }
        },
        url: `${API_URL}/${API_ROUTES.LISTINGS}/${data.code}`,
        options: {
          method: 'PATCH'
        }
      };

      return dispatch({
        req,
        type: ACTION.UPDATE_LISTING_PRICE_REQ
      });
    }

    handleChange(e, { code: data.code });

    addCodeValue({
      code: data.code,
      name: currentInput === 'ticket_price' ? 'ticket_receive' : 'ticket_price',
      value: oppositeValue
    });
  };

  return (
    <Input
      code={data.code}
      location={data.location.id}
      name={name}
      onBlur={e => handleValue(e, data.location.id, values)}
      onChange={e => handleValue(e, data.location.id)}
      type="number"
      value={(values[data.code] && values[data.code][name].value) || ''}
    />
  );
};

const columns = [
  {
    allowFiltering: false,
    allowUpdating: false,
    caption: 'Código',
    dataField: 'code',
    cellRender: ({ data }) => <EventId data={data} />
  },
  {
    allowEditing: true,
    allowUpdating: false,
    caption: 'Ubicación',
    dataField: 'location.name'
  },
  {
    allowFiltering: false,
    alignment: 'center',
    allowUpdating: false,
    caption: 'Fila',
    dataField: 'location.row'
  },
  {
    allowFiltering: false,
    alignment: 'center',
    allowUpdating: false,
    caption: 'Sector',
    dataField: 'location.section.name'
  },
  {
    allowFiltering: false,
    alignment: 'center',
    allowUpdating: false,
    caption: 'Cantidad',
    dataField: 'tickets',
    width: 80
  },
  {
    allowFiltering: false,
    allowGrouping: false,
    caption: 'Precio de venta',
    cellRender: ({ data }) => <PriceInput data={data} name="ticket_price" />,
    cssClass: 'table-input-cell',
    width: 130
  },
  {
    allowFiltering: false,
    allowGrouping: false,
    caption: 'A recibir',
    cellRender: ({ data }) => <PriceInput data={data} name="ticket_receive" />,
    cssClass: 'table-input-cell',
    width: 130
  },
  {
    alignment: 'center',
    caption: 'Publicada',
    cellRender: ({ data }) => <ActiveSwitch code={data.code} status={data.status} />,
    width: 90
  },
  {
    cellRender: ({ data }) => <ActionIconsContainer data={data} type={'DUPLICATE'} />,
    cssClass: 'table-action-button'
  },
  {
    cellRender: ({ data }) => <ActionIconsContainer data={data} type={'EDIT'} />,
    cssClass: 'table-action-button'
  },
  {
    cellRender: ({ data }) => <ActionIconsContainer data={data} type={'DELETE'} />,
    cssClass: 'table-action-button'
  }
];

export default columns;
