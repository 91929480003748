const ACTION = {
  SALE_ADDITIONAL_DATA: 'SALE_ADDITIONAL_DATA',
  SALE_ADDITIONAL_DATA_REQ: 'EVENT_ADDITIONAL_DATA_REQ',
  SALE_DETAIL_DATA: 'SALE_DETAIL_DATA',
  CLEAR_DRAWER_DATA: 'CLEAR_DRAWER_DATA',
  DRAWER_OPEN: 'DRAWER_OPEN',
  STATUS_FILTERED_SALES: 'STATUS_FILTERED_SALES',
  ISLOADING: 'ISLOADING',
  SALES_AGGREGATES: 'SALES_AGGREGATES',
  STATUS_FILTER: 'STATUS_FILTER',
  IS_GENERATING_TICKETS: 'IS_GENERATING_TICKETS',
  PRIMARY_PLATFORM_LINK: 'PRIMARY_PLATFORM_LINK',
  TICKET_PRINT_QUEUE: 'TICKET_PRINT_QUEUE',
  TICKET_PRINT_REQ: 'TICKET_PRINT_REQ',
  TICKET_PRINT_FAIL: 'TICKET_PRINT_FAIL'
};

export default ACTION;