import React, { useReducer } from 'react';
import ACTION from './actionTypes';
import { removeLettersFromId, getTimestamp } from '../../helpers/helpers';

export const ListingsStore = React.createContext('');

const initialState = {
  activeListings: [],
  duplicateQueue: [],
  compareListingReq: {},
  compareListingData: null,
  comparingActive: false,
  removeQueue: [],
  eventListings: [],
  editListing: {},
  updateListingGroupStatus: {},
  batchManageListingsReq: {},
  setActiveListingReq: {},
  openPublicationDrawer: false,
  updateListingPriceReq: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.SET_EVENT_LISTINGS:
      return {
        ...state,
        eventListings: action.payload
      };

    case ACTION.COLLAPSED_EVENT_LISTINGS:
      return {
        ...state,
        activeListings: [
          ...state.activeListings,
          {
            code: action.code,
            payload: action.payload
          }
        ]
      };

    case ACTION.COMPARE_LISTING_REQ:
      return {
        ...state,
        compareListingReq: action.req
      };

    case ACTION.COMPARE_LISTING_DATA:
      return {
        ...state,
        compareListingData: action.compareListingData
      };

    case ACTION.COMPARE_ACTIVE:
      return {
        ...state,
        comparingActive: !state.comparingActive,
        compareListingData: !!state.comparingActive ? null : state.compareListingData,
        compareListingReq: !!state.comparingActive ? {} : state.compareListingReq
      };

    case ACTION.UPDATE_LISTING_PRICE_REQ:
      return {
        ...state,
        updateListingPriceReq: action.req
      };

    case ACTION.ADD_NEW_LISTING:
      const newListing = {
        ...action.listing.event,
        date: null,
        code: `E${action.listing.event.id}`,
        listings: 1,
        tickets: action.listing.tickets
      };

      return {
        ...state,
        eventListings: [...state.eventListings, newListing]
      };

    case ACTION.UPDATE_LISTING:
      const replaceListing = ({ payload, newListing }) =>
        payload.map(listing => {
          return listing.code === newListing.code ? newListing : listing;
        });

      const updatedListings = state.activeListings.map(event => {
        const id = removeLettersFromId(event.code);

        if (id === action.listing.event.id) {
          const newPayload = action.isEditing
            ? replaceListing({ payload: event.payload, newListing: action.listing })
            : [action.listing, ...event.payload];

          return {
            ...event,
            payload: newPayload
          };
        }
        return event;
      });

      return {
        ...state,
        activeListings: updatedListings
      };

    case ACTION.REMOVE_LISTING:
      const listingsEventRemoval = state.activeListings.map(listing => {
        if (listing.code === action.listingId) {
          const { payload } = listing;
          const updatedPayload = payload.filter(({ code }) => code !== action.eventCode);

          return {
            ...listing,
            payload: updatedPayload
          };
        }

        return {
          ...listing
        };
      });

      return {
        ...state,
        activeListings: listingsEventRemoval,
        removeQueue: [...state.removeQueue, action.eventCode]
      };

    case ACTION.EDIT_LISTING:
      return {
        ...state,
        openPublicationDrawer: !state.openPublicationDrawer,
        editListing: action.listingData
      };

    case ACTION.RESET_EDIT_LISTING:
      return {
        ...state,
        editListing: {}
      };

    case ACTION.UPDATE_LISTING_GROUP_STATUS:
      const { updateListingGroupStatus } = action;

      return {
        ...state,
        updateListingGroupStatus
      };

    case ACTION.REQ_PAUSE_LISTING:
      return {
        ...state,
        setActiveListingReq: action.req
      };

    case ACTION.REQ_RESUME_LISTING:
      return {
        ...state,
        setActiveListingReq: action.req
      };

    case ACTION.PAUSE_LISTING_GROUP:
      return {
        ...state,
        batchManageListingsReq: action.req
      };

    case ACTION.RESUME_LISTING_GROUP:
      return {
        ...state,
        batchManageListingsReq: action.req
      };

    case ACTION.DUPLICATE_EVENT:
      let duplicateQueue = [...state.duplicateQueue];
      const { eventCode } = action;

      const newActiveListings = state.activeListings.map(listing => {
        if (listing.code === action.listingId) {
          const ts = getTimestamp();
          const { payload } = listing;
          const targetIdx = payload.findIndex(({ code }) => eventCode === code);

          const updatedPayload = payload.map((event, idx) =>
            idx === targetIdx
              ? { ...event, fetchingId: [ts, action.eventCode].join('0') }
              : event
          );

          const newDuplicate = {
            lid: [ts, payload[targetIdx].code].join(0),
            listing: payload[targetIdx]
          };

          duplicateQueue = [...duplicateQueue, newDuplicate];

          updatedPayload.splice(targetIdx, 0, payload[targetIdx]);

          return {
            ...listing,
            payload: updatedPayload
          };
        }

        return listing;
      });

      return {
        ...state,
        activeListings: newActiveListings,
        duplicateQueue
      };

    case ACTION.UPDATE_DUPLICATE_EVENT_ID:
      const { fetchId, newDuplicate } = action;

      const updateDuplicateQueue = state.duplicateQueue.filter(
        ({ lid }) => lid !== fetchId
      );
      const updateActiveListings = state.activeListings.map(listing => {
        const eventCode = removeLettersFromId(listing.code);

        if (eventCode === newDuplicate.event.id) {
          const listingPayload = listing.payload.map(event => {
            if (event.fetchingId && event.fetchingId === fetchId) {
              return newDuplicate;
            }
            return event;
          });

          return {
            ...listing,
            payload: listingPayload
          };
        }

        return listing;
      });

      return {
        ...state,
        activeListings: updateActiveListings,
        duplicateQueue: updateDuplicateQueue
      };

    case ACTION.OPEN_PUBLICATION_DRAWER:
      return {
        ...state,
        openPublicationDrawer: !state.openPublicationDrawer
      };

    case ACTION.SET_LISTING_CONSTRAINTS:
      const listingsWithConstraints = state.activeListings.map(event => {
        if (event.code === action.code) {
          return {
            code: event.code,
            constraints: action.locations,
            payload: event.payload
          };
        }
        return event;
      });

      return {
        ...state,
        activeListings: listingsWithConstraints
      };

    default:
      return state;
  }
};

export function ListingsProvider(props) {
  return (
    <ListingsStore.Provider value={useReducer(reducer, initialState)}>
      {props.children}
    </ListingsStore.Provider>
  );
}
