import React, { useReducer } from 'react';
import ACTION from './actionTypes';

export const OrderStore = React.createContext('');

const initialState = {
  getOrderReq: {},
  orderDetail: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.ORDER_DETAIL:
      return {
        ...state,
        orderDetail: action.event
      };
    case ACTION.CLEAR_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: null,
        getOrderReq: {}
      };
    case ACTION.GET_ORDER_REQ:
      return {
        ...state,
        getOrderReq: action.req
      };
    default:
      return {
        ...state
      };
  }
};

export function OrdersProvider(props) {
  return (
    <OrderStore.Provider value={useReducer(reducer, initialState)}>
      {props.children}
    </OrderStore.Provider>
  );
}
