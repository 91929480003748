import React, { useReducer } from 'react';
import ACTION from './actionTypes';

export const PublicationStore = React.createContext('');

const initialState = {
  constraintsReqUrl: {},
  editForm: [],
  eventConstraints: {},
  step: 1,
  selectedEvent: null,
  submitPublication: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.STEP_BACKWARDS:
      return {
        ...state,
        step: state.step - 1
      };

    case ACTION.STEP_FORWARD:
      return {
        ...state,
        step: state.step + 1
      };

    case ACTION.EDIT_FORM:
      return {
        ...state,
        editForm: action.editForm
      };

    case ACTION.SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.event
      };

    case ACTION.CONSTRAINTS_REQ_URL:
      return {
        ...state,
        constraintsReqUrl: action.reqUrl
      };

    case ACTION.SUBMIT_PUBLICATION:
      return {
        ...state,
        submitPublication: action.req
      };

    case ACTION.PUBLICATION_CONSTRAINTS:
      return {
        ...state,
        eventConstraints: action.constraints
      };

    case ACTION.RESET_PUBLICATION_FLOW:
      return {
        ...initialState
      };

    default:
      return {
        ...state
      };
  }
};

export function PublicationProvider(props) {
  return (
    <PublicationStore.Provider value={useReducer(reducer, initialState)}>
      {props.children}
    </PublicationStore.Provider>
  );
}
