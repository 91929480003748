import { useState, useEffect } from 'react';
import { encodeParamsAsURI, getTimestamp } from '../helpers/helpers';
import { useAuth0 } from '../react-auth0-spa';
import { message } from 'antd';

const useFetch = ({ avoidCache, body, file, url, options, params }) => {
  const [response, setResponse] = useState({});
  const [error, setError] = useState(null);
  const [resUrl, setResUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [currentParams, setCurrentParams] = useState();

  const getReqUrl = ({ url, params, avoidCache }) => {
    let reqUrl = url;
    const timestamp = getTimestamp();

    if (params) {
      reqUrl = `${reqUrl}?${encodeParamsAsURI(params)}`;
    }

    if (avoidCache) {
      reqUrl = params ? `${reqUrl}&ts=${timestamp}` : `${reqUrl}?ts=${timestamp}`;
    }

    return reqUrl;
  };

  const { getTokenSilently } = useAuth0();

  // This function avoids JSON parse error when content-type is
  // supposed to be json but the response comes empty.
  const _parseJSON = response => {
    return response.text().then(function(text) {
      return text ? JSON.parse(text) : {};
    });
  };

  const request = async () => {

    // Prevent infinite loops with params comparison
    if (params) {
      const serializedParams = Object.values(params).join('|');
      if (serializedParams === currentParams) return;
      setCurrentParams(serializedParams);
    }

    const token = await getTokenSilently();

    setLoading(true);
    const processBody = file ? file : body && JSON.stringify(body);
    const contentType = file ? '' : { 'Content-Type': 'application/json' };

    const updatedOptions = {
      ...options,
      body: processBody,
      headers: {
        Authorization: `Bearer ${token}`,
        ...contentType
      }
    };

    try {
      const reqUrl = getReqUrl({ url, params, avoidCache });

      const res = await fetch(reqUrl, updatedOptions);
      const json = await _parseJSON(res);
      setResUrl(res.url);

      if (res.status !== 200) {
        setLoading(false);
        const { message, ...rest } = json;
        throw Object.assign(new Error(message), rest);
      }

      setLoading(false);
      setResponse(json);
    } catch (err) {
      message.error('Hubo un error al procesar tu pedido.')
      setError(err);
    }
  };

  const doFetch = () => {
    if (!!url) {
      request();
    }
  };

  useEffect(() => {
    if (!!url) request();
  }, [body, params, url]);

  useEffect(() => {
    setError(null);
  }, [body, params, url]);

  return [response, { doFetch, url: resUrl, loading, error }];
};

export default useFetch;
