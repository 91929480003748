import React from 'react';
import { Router, Switch } from 'react-router-dom';
import Header from './components/Header/Header';
import history from './helpers/history';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { useAuth0 } from './react-auth0-spa';

import Loading from './components/Loading/Loading';

import { AppProvider } from './reducers/app/appReducer';
import { ListingsProvider } from './reducers/listings/listingsReducer';
import { SalesProvider } from './reducers/sales/salesReducer';

import ListingsContainer from './containers/listings/ListingsContainer';
import SalesContainer from './containers/sales/SalesContainer';
import OrdersContainer from './containers/orders/OrdersContainer';
import ReportsContainer from './containers/reports/reportsContainer';
import AppContainer from './containers/app/AppContainer';
import { OrdersProvider } from './reducers/orders/ordersReducer';
import AccountContainer from './containers/account/AccountContainer';
import { AccountProvider } from './reducers/account/accountReducer';

const Listings = () => (
  <ListingsProvider>
    <ListingsContainer />
  </ListingsProvider>
);

const Sales = () => (
  <SalesProvider>
    <SalesContainer />
  </SalesProvider>
);

const Orders = () => (
  <OrdersProvider>
    <OrdersContainer />
  </OrdersProvider>
)

const Account = () => (
  <AccountProvider>
  	<AccountContainer />
  </AccountProvider>
)

const App = () => {
  const { isAuthenticated, loading } = useAuth0();

  if (loading) {
    return <Loading fullSize />;
  }

  return (
    <React.Fragment>
      <Router history={history}>
        <AppProvider>
          {isAuthenticated && <Header />}
          <AppContainer>
            <Switch>
              <PrivateRoute exact path="/" component={Sales} />
              <PrivateRoute path="/sales" component={Sales} />
              <PrivateRoute path="/purchases" component={Orders} />
              <PrivateRoute path="/reports" component={ReportsContainer} />
              <PrivateRoute path="/listings" component={Listings} />
              <PrivateRoute path="/account" component={Account} />
            </Switch>
          </AppContainer>
        </AppProvider>
      </Router>
    </React.Fragment>
  );
};

export default App;
