import React, { useContext, useEffect } from 'react';
import { Button, Form, Select } from 'antd';

import { AccountStore } from '../../reducers/account/accountReducer';
import { API_URL, API_ROUTES } from '../../config';
import ACTION from '../../reducers/account/actionTypes';
import useFetch from '../../hooks/useFetch';
import styled from 'styled-components';
import bankDataInputs from './bankingDataFormMappings';
import useForm from '../../hooks/useForm';
import { isEmptyObject } from '../../helpers/helpers';
import Loading from '../../components/Loading/Loading';

const initialRequests = [
  {
    actionType: ACTION.GET_BANK_TYPES_REQ,
    req: {
      url: `${API_URL}/${API_ROUTES.BANK_TYPES}`
    }
  },
  {
    actionType: ACTION.GET_BANK_ACCOUNT_TYPES_REQ,
    req: {
      url: `${API_URL}/${API_ROUTES.BANK_ACCOUNT_TYPES}`
    }
  },
  {
    actionType: ACTION.USER_PAYOUT_DATA_REQ,
    req: {
      url: `${API_URL}/${API_ROUTES.USER_PAYOUT_DATA}`
    }
  }
];

const { Item } = Form;
const { Option } = Select;

const BankDataGrid = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  .ant-input-number {
    width: 100%;
  }
`;

const StyledForm = styled(Form)`
  align-self: flex-start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 25px;
  width: 500px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 5px;
  }
`;

const UserActions = styled.div`
  display: flex;
  grid-column: 2/2;
  justify-content: flex-end;
`;

const BankingDataContainer = ({ isSubmitting, onSubmit }) => {
  const [state, dispatch] = useContext(AccountStore);
  const { addValue, handleChange, values } = useForm();

  const [bankTypes] = useFetch(state.bankTypesReq);
  const [bankAccountTypes] = useFetch(state.bankAccountTypesReq);

  const handleOnChange = (e, name) => {
    const isEvent = typeof e === 'object';
    const isSelect = ['bank', 'account_type'].includes(name);

    const payloads = {
      bank: state.bankTypes,
      account_type: state.bankAccountTypes
    };

    const value = isSelect ? payloads[name].find(({ id }) => id === e) : e;

    return isEvent ? handleChange(e) : addValue({ name, value });
  };

  useEffect(() => {
    initialRequests.forEach(({ actionType, req }) => {
      dispatch({
        req,
        type: actionType
      });
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isEmptyObject(bankAccountTypes)) {
      const { results } = bankAccountTypes;

      dispatch({
        bankAccountTypes: results,
        type: ACTION.BANK_ACCOUNT_TYPES
      });
    }

    if (!isEmptyObject(bankTypes)) {
      const { results } = bankTypes;

      dispatch({
        bankTypes: results,
        type: ACTION.BANK_TYPES
      });
    }

    // eslint-disable-next-line
  }, [bankTypes, bankAccountTypes]);

  useEffect(() => {
    const { userPayoutData } = state;

    if (userPayoutData) {
      Object.keys(userPayoutData).forEach(key => {
        addValue({ name: key, value: userPayoutData[key] });
      });
    }
  }, [state.userPayoutData]);

  return (
    <BankDataGrid>
      {isEmptyObject(values) && <Loading />}

      {!isEmptyObject(values) && (
        <React.Fragment>
          <StyledForm colon={false}>
            {bankDataInputs.map(({ component: Component, name, label }) => {
              const selectOptions =
                name === 'bank' ? state.bankTypes : state.bankAccountTypes;

              const isSelect = ['account_type', 'bank'].includes(name);

              return (
                <React.Fragment key={[name, label].join('|')}>
                  <FormGroup>
                    {isSelect && (
                      <Item label={label}>
                        <Component
                          name={name}
                          disabled={isSubmitting}
                          loading={!selectOptions.length}
                          onChange={e => handleOnChange(e, name)}
                          value={values[name] && values[name].name}
                        >
                          {selectOptions.map(({ name, id }) => (
                            <Option key={id} value={id}>
                              {name}
                            </Option>
                          ))}
                        </Component>
                      </Item>
                    )}

                    {!isSelect && (
                      <Item label={label}>
                        <Component
                          name={name}
                          disabled={isSubmitting}
                          onChange={e => handleOnChange(e, name)}
                          value={values[name] || ''}
                        />
                      </Item>
                    )}
                  </FormGroup>
                </React.Fragment>
              );
            })}
          </StyledForm>
          <UserActions>
            <Button
              loading={isSubmitting}
              onClick={() => onSubmit(values)}
              type="primary"
            >
              Guardar Cambios
            </Button>
          </UserActions>
        </React.Fragment>
      )}
    </BankDataGrid>
  );
};

export default BankingDataContainer;
