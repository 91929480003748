import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Menu } from 'antd';
import { useContext } from 'react';
import { AccountStore } from '../../reducers/account/accountReducer';
import ACTION from '../../reducers/account/actionTypes';
import GLOBAL_ACTION from '../../reducers/app/actionTypes';
import { API_ROUTES, API_URL } from '../../config';
import Profile from '../../components/Account/Profile';
import useFetch from '../../hooks/useFetch';
import { AppStore } from '../../reducers/app/appReducer';
import BankingDataContainer from './BankingDataContainer';

const Wrap = styled.div`
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.015), 0 0px 4px rgba(0, 0, 0, 0.015),
    0 0px 8px rgba(0, 0, 0, 0.015), 0 0px 16px rgba(0, 0, 0, 0.015),
    0 0px 32px rgba(0, 0, 0, 0.015), 0 0px 64px rgba(0, 0, 0, 0.015);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 250px 1fr;
  margin: 0 auto;
  margin-top: 35px;
  min-height: 500px;
  max-width: 1280px;
  padding: 25px 15px 15px 0px;
`;
const SidebarSelector = styled.div`
  .ant-menu-root.ant-menu-vertical {
    border: none;
  }
`;
const DisplayData = styled.div`
  align-items: center;
  display: flex;
  padding-left: 25px;
  position: relative;

  &:after {
    background-color: #f3f4f8;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;
  }
`;

const SIDEBAR_OPTIONS = [
  {
    id: 0,
    name: 'Datos de la cuenta'
  },
  {
    id: 1,
    name: 'Datos bancarios'
  }
  // {
  //   id: 2,
  //   name: 'Ayuda'
  // }
];

const AccountContainer = () => {
  const [state, dispatch] = useContext(AccountStore);
  const [, appDispatcher] = useContext(AppStore);

  const selectedOption = SIDEBAR_OPTIONS.find(({ id }) => id === state.selectedOption);

  const [userData] = useFetch(state.userDataReq);
  const [userPayoutData] = useFetch(state.userPayoutDataReq);

  const [submitProfile, { loading: loadingProfileData }] = useFetch(
    state.submitProfileReq
  );
  const [submitPayoutData, { loading: loadingPayoutData }] = useFetch(
    state.userPayoutDataReq
  );

  useEffect(() => {
    dispatch({
      req: {
        url: `${API_URL}/${API_ROUTES.USER}`
      },
      type: ACTION.GET_USER_DATA_REQ
    });

    dispatch({
      req: {
        url: `${API_URL}/${API_ROUTES.IDENTITY_TYPES}`
      },
      type: ACTION.GET_IDENTITY_TYPES_REQ
    });
  }, []);

  useEffect(() => {
    if (userData) {
      dispatch({
        userData,
        type: ACTION.USER_DATA
      });
    }
  }, [userData]);

  useEffect(() => {
    if (userPayoutData) {
      dispatch({
        userPayoutData,
        type: ACTION.USER_PAYOUT_DATA
      });
    }
  }, [userPayoutData]);

  useEffect(() => {
    const { error } = submitPayoutData;

    if (error) {
      return appDispatcher({
        fetchError: error,
        type: GLOBAL_ACTION.FETCH_ERRORS
      })
    }

    dispatch({
      userPayoutData: submitPayoutData,
      type: ACTION.USER_PAYOUT_DATA
    })

  }, [submitPayoutData])

  useEffect(() => {
    const { error } = submitProfile;

    if (error) {
      return appDispatcher({
        fetchError: error,
        type: GLOBAL_ACTION.FETCH_ERRORS
      });
    }

    dispatch({
      userData: submitProfile,
      type: ACTION.USER_DATA
    });
  }, [submitProfile]);

  const handleProfileSubmit = values => {
    const req = {
      body: {
        ...values
      },
      url: `${API_URL}/${API_ROUTES.USER}`,
      options: {
        method: 'PUT'
      }
    };

    dispatch({
      req,
      type: ACTION.SUBMIT_PROFILE_REQ
    });
  };

  const handleBankDataSubmit = values => {
    const req = {
      body: { ...values },
      url: `${API_URL}/${API_ROUTES.USER_PAYOUT_DATA}`,
      options: {
        method: 'PUT'
      }
    };

    dispatch({
      req,
      type: ACTION.USER_PAYOUT_DATA_REQ
    });
  };

  return (
    <Wrap>
      <SidebarSelector>
        <Menu defaultSelectedKeys={['0']}>
          {SIDEBAR_OPTIONS.map(({ id, name }) => (
            <Menu.Item
              active={id === state.selectedOption ? true : undefined}
              key={id}
              onClick={() => dispatch({ id, type: ACTION.SELECTED_OPTION })}
            >
              {name}
            </Menu.Item>
          ))}
        </Menu>
      </SidebarSelector>
      <DisplayData>
        {selectedOption.id === 0 && (
          <Profile isSubmitting={loadingProfileData} onSubmit={handleProfileSubmit} />
        )}
        {selectedOption.id === 1 && (
          <BankingDataContainer
            isSubmitting={loadingPayoutData}
            onSubmit={handleBankDataSubmit}
          />
        )}
        {selectedOption.id >= 2 && <h3>No hay datos.</h3>}
      </DisplayData>
    </Wrap>
  );
};

export default AccountContainer;
