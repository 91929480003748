import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '../../react-auth0-spa';
import { Button } from 'antd';

const MenuWrap = styled.div`
  margin-left: 20px;

  .menu-link {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;

    &:after {
      background-color: #1da2ff;
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      transform: translateY(2px);
      transition: all 300ms;
      width: 100%;
    }
  }

  .active {
    &:after {
      transform: translateY(0)
    }
  }

  ul {
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    justify-content: flex-end;
    padding: 0;
    margin: 0;

    li {
      height: 50px;
      list-style: none;
    }
  }
`;

const Menu = () => {
  const { logout, userFlags, user } = useAuth0();
  const { isBetaUser, isBetaAdmin } = userFlags;

  const { country } = user;
  const hasFullAccess = isBetaAdmin || country === 'CL'

  const logoutParams = {
    returnTo: window.location.origin
  };

  return (
    <React.Fragment>
      <MenuWrap>
        <ul>
          {(isBetaUser || hasFullAccess) && (
            <li>
              <NavLink className="menu-link" activeClassName="active" to="/sales">Ventas Realizadas</NavLink>
            </li>
          )}
          {hasFullAccess && (
            <React.Fragment>
              <li>
                <NavLink className="menu-link" activeClassName="active" to="/purchases">Compras Realizadas</NavLink>
              </li>
              {/* <li>
                <NavLink className="menu-link" activeClassName="active" to="/listings">Mis Publicaciones</NavLink>
              </li> */}
              {/* <li>
                <NavLink className="menu-link" activeClassName="active" to="/reports">Reportes</NavLink>
              </li> */}
              {/* <li>
                <NavLink className="menu-link" activeClassName="active" to="/account">Mis Datos</NavLink>
              </li> */}
            </React.Fragment>
          )}
        </ul>
      </MenuWrap>
      <Button type="link" icon="poweroff" onClick={() => logout(logoutParams)}>
        Salir
      </Button>
    </React.Fragment>
  );
};

export default Menu;
