import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import columns from './tableConfig';
import DataGrid, {
  Column,
  Export,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  Scrolling,
  SearchPanel,
  Selection
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react';
import NotFound from '../../EmptyState/NotFound';
import Loading from '../../Loading/Loading';
import { formatDate, DATE_PARSER } from '../../../helpers/helpers';

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  padding: 0 15px;
`;

const Sales = ({ data, isLoading, flags, onGenerateLabels, onRowClick }) => {
  const [itemsChecked, setItemsChecked] = useState([]);
  const { confirmedStatus } = flags;

  const itemsSelected = !!itemsChecked.length;
  const dataAvailable = data && data.length > 0;

  const dataGridRef = useRef(null);

  useEffect(() => {
    const { current } = dataGridRef;

    if (confirmedStatus && current) {
      const dateFormatted = data => formatDate({
        date: data.in_hand_date,
        style: DATE_PARSER.ABBREVIATED
      });

      const shipLimitDate = {
        allowFiltering: false,
        allowSearch: true,
        caption: 'Envío Límite',
        dataField: 'in_hand_date',
        calculateCellValue: dateFormatted,
        visibleIndex: 8
      };

      const shipColumn = {
        allowFiltering: false,
        allowSearch: true,
        caption: 'Envío',
        dataField: 'delivery_method.name',
        visibleIndex: 7
      };

      current.instance.addColumn(shipColumn);
      current.instance.addColumn(shipLimitDate);
    }

  }, [dataGridRef, dataAvailable, isLoading]);

  return (
    <React.Fragment>
      {dataAvailable && !isLoading && (
        <div id="grid">
          <DataGrid
            allowColumnResizing={true}
            columnAutoWidth
            dataSource={data}
            noDataText="No se encotraron resultados."
            onRowClick={({ data }) => onRowClick(data)}
            onSelectionChanged={({ selectedRowsData }) =>
              setItemsChecked(selectedRowsData)
            }
            rowAlternationEnabled
            showColumnLines={false}
            ref={dataGridRef}
          >
            <GroupPanel emptyPanelText={'Agrupar por columnas'} visible={true} />
            <SearchPanel
              highlightCaseSensitive={true}
              placeholder={'Buscar'}
              visible={true}
            />
            <Export
              allowExportSelectedData={true}
              enabled={true}
              fileName={'Sales'}
              onClick
            />
            <Grouping autoExpandAll={false} />
            <Selection mode={'multiple'} showCheckBoxesMode={'always'} />
            <Scrolling columnRenderingMode={'virtual'} />

            {columns.map(props => (
              <Column key={Object.values(props).join('|')} {...props} />
            ))}
            <Paging defaultPageSize={20} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} />
          </DataGrid>

          {confirmedStatus && (
            <ActionBar>
              <Button
                disabled={!itemsSelected}
                onClick={() => onGenerateLabels(itemsChecked)}
                text="Generar Etiquetas"
              />
            </ActionBar>
          )}
        </div>
      )}

      {!dataAvailable && !isLoading && (
        <NotFound text="Aún no tenés ninguna venta para mostrar." />
      )}

      {isLoading && <Loading fullSize />}
    </React.Fragment>
  );
};

export { Sales };
