import React, { useContext, useEffect, useState } from 'react';

import { ListingsStore } from '../../reducers/listings/listingsReducer';
import ACTION from '../../reducers/listings/actionTypes';

import ListingsDetail from '../../components/Tables/ListingsDetail/ListingsDetail';
import useForm from '../../hooks/useForm';
import Loading from '../../components/Loading/Loading';
import useFetch from '../../hooks/useFetch';
import { API_URL, API_ROUTES } from '../../config';
import DataSource from 'devextreme/data/data_source';
import { isEmptyObject, removeLettersFromId } from '../../helpers/helpers';

const ListingsDetailContainer = ({ data }) => {
  const [state, dispatch] = useContext(ListingsStore);
  const { addCodeValue, values, handleChange } = useForm();
  const [reqConstraints, setReqConstraints] = useState({});
  const [duplicateReq, setDuplicateReq] = useState({});
  const [removeReq, setRemoveReq] = useState({});
  const [payload, setPayload] = useState(null);

  const { duplicateQueue, removeQueue } = state;

  const reqListingsByEvent = {
    avoidCache: true,
    url: `${API_URL}/${API_ROUTES.LISTINGS}`,
    params: {
      event_code: data.code
    }
  };

  const [listingsByEvent] = useFetch(reqListingsByEvent);
  const [updatedListingPrice] = useFetch(state.updateListingPriceReq)

  useEffect(() => {
    const { results } = listingsByEvent;

    if (results) {
      // ToDo: Location value type is string and should be int.
      // This leads to problems when matching constraints data where
      // id for location is set to int.
      const castedResults = results.map(({ location, ...rest }) => {
        return {
          ...rest,
          location: {
            id: location.id.toString(),
            ...location
          }
        };
      });

      dispatch({
        code: data.code,
        payload: castedResults,
        type: ACTION.COLLAPSED_EVENT_LISTINGS
      });
    }
    // eslint-disable-next-line
  }, [listingsByEvent]);

  useEffect(() => {
    if (!data) return;
    const { code } = data;
    const eventId = removeLettersFromId(code)

    if (!!state.activeListings.length) {
      setReqConstraints({
        url: `${API_URL}/events/${eventId}/constraints`
      });
    }

    setDuplicateReq({});

    const listingData = state.activeListings.find(({ code }) => code === data.code);

    if (listingData) {
      const { payload } = listingData;

      const store = new DataSource({
        store: payload
      });

      setPayload(store);
    }
  }, [data, state.activeListings]);

  useEffect(() => {
    const { actionType, eventId } = state.updateListingGroupStatus;
    const isSameEvent = eventId === removeLettersFromId(data.code)

    const noData =
      isEmptyObject(state.updateListingGroupStatus) || !state.activeListings.length || !isSameEvent

    if (noData) return;

    const LISTING_STATUS = {
      ACTIVE: 'active',
      INACTIVE: 'inactive'
    };

    const status =
      actionType === 'publish' ? LISTING_STATUS.ACTIVE : LISTING_STATUS.INACTIVE;

    const target = state.activeListings.find(({ code }) => code === `E${eventId}`)

    const replaceTargetStatus = target.payload.map(item => ({ ...item, status }))

    const store = new DataSource({
      store: replaceTargetStatus
    })

    setPayload(store)

  }, [state.updateListingGroupStatus]);

  const [constraintsByEvent] = useFetch(reqConstraints);

  useEffect(() => {
    const { code } = data;
    const { locations } = constraintsByEvent;

    if (locations) {
      dispatch({
        code,
        locations,
        type: ACTION.SET_LISTING_CONSTRAINTS
      });
    }
  }, [constraintsByEvent]);

  const [duplicateResponse, { url: originUrl }] = useFetch(duplicateReq);

  useEffect(() => {
    duplicateQueue.map(({ listing, lid }) => {
      const url = `${API_URL}/${API_ROUTES.LISTINGS}?lid=${lid}`;

      return setDuplicateReq({
        body: listing,
        url,
        options: {
          method: 'POST'
        }
      });
    });
  }, [duplicateQueue]);

  useFetch(removeReq);

  useEffect(() => {
    removeQueue.map(id => {
      const url = `${API_URL}/${API_ROUTES.LISTINGS}/${id}`;

      return setRemoveReq({
        url,
        options: {
          method: 'DELETE'
        }
      });
    });
  }, [removeQueue]);

  useEffect(() => {
    const emptyResponse = !originUrl || isEmptyObject(duplicateResponse);
    const LISTING_ID = 'lid'

    if (emptyResponse) return;

    const eventUrl = new URLSearchParams(originUrl.split('?').pop())
    const fetchId = eventUrl.get(LISTING_ID)

    dispatch({
      fetchId,
      newDuplicate: duplicateResponse,
      type: ACTION.UPDATE_DUPLICATE_EVENT_ID
    });
  }, [duplicateResponse]);

  // useEffect(() => {
  //   if (!isEmptyObject(updatedListingPrice)) {
  //     dispatch({
  //       isEditing: true,
  //       listing: updatedListingPrice,
  //       type: ACTION.UPDATE_LISTING
  //     })
  //   }
  // }, [updatedListingPrice])

  return payload && constraintsByEvent ? (
    <FormContext.Provider value={{ addCodeValue, values, handleChange }}>
      <ListingsDetail dataSource={payload} />
    </FormContext.Provider>
  ) : (
    <Loading />
  );
};

export const FormContext = React.createContext();
export default ListingsDetailContainer;
