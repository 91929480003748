import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form, Input, Select, InputNumber } from 'antd';
import useForm from '../../hooks/useForm';
import Loading from '../Loading/Loading';
import { prepearePublication } from './preparePublication';
import { fixDecimals } from '../../helpers/helpers';

const { Item } = Form;

const StyledForm = styled(Form)`
  display: grid;
  grid-template-areas:
    'location location section row'
    'ticket_receive ticket_receive ticket_price ticket_price'
    'tickets tickets split_type split_type';
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 24px;

  .ant-input-number {
    width: 100%;
  }
`;

const StyledItem = styled(Item)`
  margin-bottom: 0 !important;

  ${({ gridArea }) => gridArea && `grid-area: ${gridArea}`}
  ${({ isOnlyNumbered }) =>
    isOnlyNumbered &&
    `
    grid-column: 3/3;
    width: 80px;
  `}

.ant-select-selection {
  /* box-shadow: ${props => (props.isValid ? 'none' : '0 0 0px 1px #fa4e56')}; */
  ${({ isValid }) => !isValid && `border: 1px #fa4e56 solid`}
}
input {
    box-shadow: ${props => (props.isValid ? 'none' : '0 0 0px 1px #fa4e56')};
  }
`;

const ActionButtons = styled.div`
  bottom: 15px;
  display: flex;
  grid-column: 5/1;
  justify-content: flex-end;
  position: absolute;
  right: 15px;

  button {
    width: 105px;

    &:first-child {
      margin-right: 5px;
    }
  }
`;

const { Option } = Select;

const PublicationForm = ({
  constraints,
  event,
  form,
  isEditForm,
  loading,
  onClose,
  onFormSubmit
}) => {
  const [locationConstraints, setLocationConstraints] = useState({
    id: null,
    numbered: false,
    sections: [],
    ticket_price_coefficient: 0
  });
  const [errors, setErrors] = useState({});

  const { addValue, values } = useForm();

  const { locations, split_types } = constraints;

  const removeError = (errorState, prop) => ({ [prop]: omit, ...errorState }) =>
    errorState;

  const getLocationConstraints = ({ locations, locationId }) =>
    locations.find(({ id }) => id.toString() === locationId.toString()) || {};

  const getLocationName = locationId => {
    const { name } =
      locations.find(({ id }) => id.toString() === locationId.toString()) || {};
    return name;
  };

  const submitPublication = formData => {
    const formErrors = Object.keys(formData.values).filter(key => {
      const exclusions = ['section', 'row'];

      if (values[key] === '' && !exclusions.includes(key)) {
        setErrors(errors => ({ ...errors, [key]: 'error' }));
        return {
          [key]: 'error'
        };
      }

      return null;
    });

    if (!formErrors.length) {
      const reqData = prepearePublication(formData);
      onFormSubmit(reqData);
    }
  };

  const handleValue = ({ name, value }) => {
    if (!value) return;

    setErrors(removeError(errors, name));

    addValue({
      name,
      value
    });

    const { ticket_price_coefficient: coefficient } = locationConstraints;

    if (name === 'location') {
      ['section', 'row'].forEach(item => addValue({ name: item, value: '' }));
    }

    if (name === 'ticket_price' || name === 'ticket_receive') {
      const numberValue = Number(value);
      const oppositeValue =
        name === 'ticket_price'
          ? fixDecimals(numberValue * coefficient, 2)
          : fixDecimals(numberValue / coefficient, 2);

      const opposite = name === 'ticket_price' ? 'ticket_receive' : 'ticket_price';
      setErrors(removeError(errors, opposite));

      addValue({
        name: opposite,
        value: oppositeValue
      });

      return;
    }
  };

  useEffect(() => {
    form.forEach(({ name, value }) =>
      addValue({
        name,
        value: value || ''
      })
    );
  }, [form]);

  // Looks for location changes and updates contstraints.
  useEffect(() => {
    const { split_types, locations } = constraints;
    if (locations && !!locations.length) {
      const { numbered, sections, ticket_price_coefficient } = getLocationConstraints({
        locations,
        locationId: values.location === '' ? locations[0].id : values.location
      });

      setLocationConstraints({
        id: values.location,
        numbered,
        sections,
        ticket_price_coefficient
      });

      // Set split_types default value when constraints are loaded.
      addValue({
        name: 'split_type',
        value: split_types[0].code
      });
    }
  }, [constraints, values.location]);

  const { sections, numbered } = locationConstraints;

  return locations && split_types ? (
    <StyledForm colon={false}>
      {form.map(({ name, label, type }) => {
        const nonSelectInput = !['split_type', 'location', 'section'].includes(name);
        const InputComponent = type === 'number' ? InputNumber : Input;

        return (
          <React.Fragment key={name}>
            {name === 'location' && (
              <StyledItem className isValid={!errors[name]} label={label} gridArea={name}>
                <Select
                  defaultValue={values.location}
                  value={getLocationName(values.location)}
                  optionLabelProp="label"
                  onChange={value => handleValue({ name, value })}
                >
                  {locations.map(({ id, name }) => (
                    <Option key={name} value={id} label={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </StyledItem>
            )}

            {name === 'section' && sections && sections.length > 0 && (
              <StyledItem className isValid={!errors[name]} label={label} gridArea={name}>
                <Select
                  defaultValue={sections[0].value}
                  value={values[name].name}
                  onChange={value => {
                    const section = sections.find(({ id }) => id === value);
                    handleValue({ name, value: section });
                  }}
                >
                  {sections.map(({ id, name }) => (
                    <Option key={name} value={id} label={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </StyledItem>
            )}

            {name === 'row' && numbered && (
              <StyledItem
                className
                gridArea={name}
                isOnlyNumbered={numbered && !!sections.length === false}
                isValid={!errors[name]}
                label={label}
              >
                <Input
                  id={name}
                  name={name}
                  type={type}
                  onChange={({ currentTarget }) =>
                    handleValue({ name, value: currentTarget.value })
                  }
                  value={values[name]}
                />
              </StyledItem>
            )}

            {name === 'split_type' && (
              <StyledItem className isValid={!errors[name]} label={label} gridArea={name}>
                <Select
                  defaultValue={values[name] === '' ? split_types[0].name : values[name]}
                  onChange={value => handleValue({ name, value })}
                >
                  {split_types.map(({ id, code, name }) => (
                    <Option key={id} value={code} label={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </StyledItem>
            )}

            {nonSelectInput && name !== 'row' && (
              <StyledItem className isValid={!errors[name]} label={label} gridArea={name}>
                <InputComponent
                  id={name}
                  name={name}
                  min={0}
                  type={type}
                  onChange={value => handleValue({ name, value })}
                  value={values[name]}
                />
              </StyledItem>
            )}
          </React.Fragment>
        );
      })}

      <ActionButtons>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          loading={loading}
          onClick={() => submitPublication({ constraints, event, values })}
          type="primary"
        >
          {isEditForm ? 'Guardar' : 'Crear'}
        </Button>
      </ActionButtons>
    </StyledForm>
  ) : (
    <Loading />
  );
};

export default PublicationForm;
