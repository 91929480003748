import React from 'react';
import HeadEvent from '../../HeadEvent/HeadEvent';
import { Button, Tooltip } from 'antd';
import { useContext } from 'react';
import { ListingsStore } from '../../../reducers/listings/listingsReducer';
import ACTION from '../../../reducers/listings/actionTypes';
import { removeLettersFromId, getTimestamp } from '../../../helpers/helpers';
import { API_URL, API_ROUTES } from '../../../config';

const ICON_CONFIG = {
  COMPARE: {
    TOOLTIP: 'Comparar subasta'
  },
  PAUSE: {
    TOOLTIP: 'Pausar publicaciones'
  },
  RESUME: {
    TOOLTIP: 'Activar publicaciones'
  }
};

const ActionButton = ({ action, event, icon, textHint }) => {
  const [, dispatch] = useContext(ListingsStore);
  const eventId = removeLettersFromId(event.code);
  const actionType = action === ACTION.PAUSE_LISTING_GROUP ? 'unpublish' : 'publish';

  const handleAction = ({ eventId, type }) => {
    const timestamp = getTimestamp()

    if (type === ACTION.COMPARE_LISTING_REQ) {
      dispatch({
        req: {
          avoidCache: true,
          url: `${API_URL}/${API_ROUTES.EVENTS}/${eventId}/marketlistings`
        },
        type: ACTION.COMPARE_LISTING_REQ
      });

      dispatch({
        type: ACTION.COMPARE_ACTIVE
      })

      return;
    }

    dispatch({
      req: {
        options: {
          method: 'POST'
        },
        url: `${API_URL}/${API_ROUTES.EVENTS}/${eventId}/${API_ROUTES.LISTINGS}/${actionType}?ts=${timestamp}`
      },
      type
    });

    dispatch({
      updateListingGroupStatus: {
        eventId,
        actionType
      },
      type: ACTION.UPDATE_LISTING_GROUP_STATUS
    });
  };

  return (
    <Tooltip placement="top" title={textHint}>
      <Button
        icon={icon}
        onClick={() => handleAction({ eventId, type: action })}
        shape="circle"
        size="small"
        type="primary"
      />
    </Tooltip>
  );
};

const columns = [
  {
    allowSearch: true,
    caption: 'Evento',
    calculateCellValue: ({ name }) => name,
    cellRender: ({ data }) => <HeadEvent event={data} />,
    dataField: 'event'
  },
  {
    dataField: 'listings',
    caption: 'Publicaciones'
  },
  {
    dataField: 'tickets',
    caption: 'Cantidad'
  },
  {
    cssClass: 'action-buttons',
    cellRender: ({ data }) => (
      <ActionButton
        action={ACTION.COMPARE_LISTING_REQ}
        event={data}
        icon="stock"
        textHint={ICON_CONFIG.COMPARE.TOOLTIP}
      />
    ),
    width: 40
  },
  {
    cssClass: 'action-buttons',
    cellRender: ({ data }) => (
      <ActionButton
        action={ACTION.PAUSE_LISTING_GROUP}
        event={data}
        icon="pause-circle"
        textHint={ICON_CONFIG.PAUSE.TOOLTIP}
      />
    ),
    width: 40
  },
  {
    cssClass: 'action-buttons',
    cellRender: ({ data }) => (
      <ActionButton
        action={ACTION.RESUME_LISTING_GROUP}
        event={data}
        icon="play-circle"
        textHint={ICON_CONFIG.RESUME.TOOLTIP}
      />
    ),
    width: 40
  }
];

export default columns;
