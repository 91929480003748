const ACTION = {
  CONSTRAINTS_REQ_URL: 'CONSTRAINTS_REQ_URL',
  SUBMIT_PUBLICATION: 'SUBMIT_PUBLICATION',
  PUBLICATION_CONSTRAINTS: 'PUBLICATION_CONSTRAINTS',
  RESET_PUBLICATION_FLOW: 'RESET_PUBLICATION_FLOW',
  EVENT_EDITING: 'EVENT_EDITING',
  EDIT_FORM: 'EDIT_FORM',
  SELECTED_EVENT: 'SELECTED_EVENT',
  SELECTED_EVENT_LOCATION: 'SELECTED_EVENT_LOCATION',
  STEP_FORWARD: 'STEP_FORWARD',
  STEP_BACKWARDS: 'STEP_BACKWARDS'
};

export default ACTION;
