import React from 'react';
import styled from 'styled-components';
import SIZES from '../../VisualStyles/sizes';
import DS from '../../VisualStyles';

const MessageWrap = styled.div`
  align-items: center;
  color: ${DS.COLORS.SUBTITLE.SECONDARY};
  display: flex;
  font-size: 15px;
  flex-direction: column;
  height: calc(100vh - ${SIZES.NAVBAR} - 30px);
  justify-content: center;
  width: 100%;
`

const TextWrap = styled.div`
  font-size: 24px;
  margin-top: 15px;
`

const NotFound = ({ text }) => (
  <MessageWrap>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="302"
      height="169"
      viewBox="0 0 302 169"
    >
      <defs>
        <path id="a" d="M0 .428h301.612v11.29H0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 157.161)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a"></use>
          </mask>
          <path
            fill="#F0F4F6"
            d="M301.612 6.073c0 3.117-67.518 5.644-150.806 5.644C67.518 11.717 0 9.19 0 6.073 0 2.955 67.518.428 150.806.428c83.288 0 150.806 2.527 150.806 5.645L91.696 17.177"
            mask="url(#b)"
          ></path>
        </g>
        <path
          fill="#AEB9C3"
          d="M124.686 55.61c-6.15-2.249-12.956.922-15.201 7.08l-7.936 21.771 20.135 4.395 9.236-16.214c.31-.553.614-1.195.838-1.811 2.245-6.159-.92-12.974-7.072-15.222"
        ></path>
        <path fill="#808993" d="M93.76 108.837l-1.652 1.526-.412 1.129z"></path>
        <path
          fill="#808993"
          d="M92.108 110.363l1.653-1.526-2.064 2.655.41-1.129-43.474 40.146c-1.312 1.208 2.9 6.72 4.515 5.569l50.005-35.655a5.28 5.28 0 001.495-1.655l.57-1 16.467-28.911-20.135-4.396-9.442 25.902zm99.177-19.93s14.431 26.807-8.072 53.847c0 0 27.262 2.641 53.536 11.406.55.184.923.704.923 1.286v4.433c0 .747-.605 1.352-1.35 1.352h-64.053c-8.277 0-13.886-8.438-10.692-16.084l22.086-52.868 7.622-3.372z"
        ></path>
        <path
          fill="#AEB9C3"
          d="M212.741 132.588a1.35 1.35 0 00-1.618-1.284l-54.84 11.13c8.742-2.596 21.525-8.376 31.88-21.138 17.554-21.632-4.95-41.462-4.95-41.462l-63.423 65.047c-8.572 8.183-2.463 19.013 9.38 19.013 31.28 0 55.783-8.453 83.073-25.061.415-.253.665-.713.651-1.2l-.153-5.045z"
        ></path>
        <path
          fill="#F2F5F8"
          d="M101.524 51.668V66.54s25.589 37.726 51.884 43.94l29.805-30.646s-20.929-47.996-81.689-28.166"
        ></path>
        <path
          fill="#4B5763"
          d="M30.202 60.244c-2.539 0-5.068.557-7.436 1.666a17.48 17.48 0 00-9.082 9.944 17.512 17.512 0 00.601 13.462c4.106 8.793 14.591 12.603 23.375 8.492a17.484 17.484 0 009.082-9.944 17.512 17.512 0 00-.601-13.461 17.478 17.478 0 00-9.93-9.094 17.577 17.577 0 00-6.009-1.065m.047 40.716c-8.687 0-17.02-4.932-20.936-13.317a22.976 22.976 0 01-.788-17.663c2.102-5.8 6.334-10.434 11.917-13.048a22.892 22.892 0 0117.64-.789c5.793 2.105 10.42 6.342 13.03 11.932a22.975 22.975 0 01.789 17.663c-2.102 5.8-6.334 10.435-11.917 13.048a22.913 22.913 0 01-9.735 2.174M51.3 126.28a2.744 2.744 0 01-2.488-1.585l-9.25-19.81a2.745 2.745 0 114.972-2.327l9.25 19.81a2.75 2.75 0 01-2.484 3.912"
        ></path>
        <path
          fill="#AEB9C3"
          d="M74.295 37.021S73.79 70.096 95.84 69.805c5.502-.072 10.146-4.155 10.934-9.608.523-3.613.663-8.732-.074-15.965-2.25-22.083-32.405-7.211-32.405-7.211"
        ></path>
        <path
          fill="#DDE4EC"
          d="M99.128 61.75c-6.517-3.921-4.192-17.123-7.051-18.85-1.262-.76-2.695-.937-4.095-.956-4.781-.064-9.459 1.561-14.173 2.564a55.215 55.215 0 01-23.68-.148c-3.386-.763-6.946-2.01-9.206-5.264-1.889-2.72-2.576-6.483-2.546-10.09.09-11.092 6.55-21.086 14.63-25.574C61.087-1.058 70.44-.723 79 2.028c4.418 1.42 8.902 3.62 11.885 7.95 1.03 1.494 1.925 3.281 3.37 4.067 2.539 1.383 5.446-.943 8.186-.452 3.3.59 5.24 4.914 6.323 8.87 2.508 9.154 3.5 47.187-9.635 39.286"
        ></path>
        <path
          fill="#AEB9C3"
          d="M104.4 46.176c.858 2.266.266 4.592-1.322 5.195-1.589.603-1.82-1.837-2.68-4.103-.857-2.266-2.015-3.5-.428-4.103 1.59-.603 3.572.745 4.43 3.011"
        ></path>
        <path
          fill="#4B5763"
          d="M213.93 130.76v7.548s2.026 22.083 6.301 24.45l2.25-.001s2.695-18.156 1.437-28.311c-.261-2.11-2.067-3.686-4.19-3.686h-5.798zm25.562 21.66h6.731s20.377 1.874 24.055 5.822c.234.251.362.582.362.925v.64c0 .677-.495 1.25-1.165 1.34-4.162.565-18.512 2.343-27.112 1.275-2.107-.262-3.679-2.071-3.679-4.197v-4.995a.81.81 0 01.808-.81M123.02 32.461a1.046 1.046 0 00-1.248.79l-.526 2.336a1.047 1.047 0 002.044.462l.526-2.337a1.041 1.041 0 00-.796-1.25m13.083-16.87c-.203-1.022-.587-1.94-1.14-2.728a7.265 7.265 0 00-2.084-1.967 8.492 8.492 0 00-2.656-1.097 9.706 9.706 0 00-2.518-.22 9.28 9.28 0 00-2.533.46 9.343 9.343 0 00-2.317 1.148 8.11 8.11 0 00-1.678 1.527c-.18.217-.265.503-.231.786.033.286.185.548.418.722.44.327 1.05.26 1.413-.16.37-.423.789-.798 1.246-1.112a7.545 7.545 0 011.779-.897 7.038 7.038 0 011.967-.376 7.621 7.621 0 012.029.177c.68.154 1.335.413 1.947.77a5.07 5.07 0 011.504 1.334c.398.532.675 1.17.824 1.894.15.732.119 1.58-.093 2.525-.21.93-.527 1.653-.94 2.145a4.768 4.768 0 01-1.452 1.182 7.422 7.422 0 01-1.791.647 82.7 82.7 0 00-2.012.472 8.162 8.162 0 00-1.539.55c-.539.254-1.04.644-1.492 1.157-.435.494-.836 1.143-1.192 1.931-.311.687-.595 1.56-.844 2.596a1.04 1.04 0 00.13.797c.15.24.383.407.658.468l.147-.66-.141.662a1.055 1.055 0 001.244-.78c.208-.863.434-1.577.673-2.124.258-.593.54-1.067.838-1.41.28-.32.573-.554.87-.694a8.345 8.345 0 011.135-.431 39.53 39.53 0 012.195-.577 9.613 9.613 0 002.285-.842 6.304 6.304 0 001.977-1.65c.575-.715 1.002-1.68 1.27-2.87.27-1.202.305-2.33.104-3.355m44.404 77.078c-6.93-4.208-9.117-12.476-9.522-14.284a.448.448 0 01.25-.505l3.495-1.624a.45.45 0 01.584.192l1.785 3.26a1.254 1.254 0 002.333-.836l-.658-3.511a.452.452 0 01.242-.487l3.58-1.793a.45.45 0 01.65.382c.429 7.456 3.036 12.845 3.036 12.845l-5.775 6.36z"
        ></path>
        <path
          fill="#FFF"
          d="M191.538 104.574c-.398 0-.784-.151-1.078-.427l-10.032-9.417a1.586 1.586 0 01-.088-2.212l6.684-7.36a.225.225 0 11.333.302l-6.684 7.361a1.133 1.133 0 00.063 1.58l10.032 9.417a1.123 1.123 0 001.684-.164c.664-.921 1.167-2.011 1.497-3.24a.225.225 0 01.434.117c-.343 1.281-.87 2.42-1.566 3.387a1.578 1.578 0 01-1.279.656"
        ></path>
        <path
          fill="#AEB9C3"
          d="M105.833 107.869l17.321-23.925 19.446 9.324-27.106 26.59-.784.77a6.252 6.252 0 01-4.098 1.78l-66.106-1.6c-1.98.086-2.505-6.85-.732-7.136l62.059-5.803"
        ></path>
        <path
          fill="#DDE4EC"
          d="M151.216 65.411c-5.302-3.848-12.714-2.665-16.558 2.644l-11.504 15.89 19.446 9.323 9.954-9.766c.45-.447.918-.981 1.301-1.512 3.844-5.308 2.662-12.73-2.64-16.579"
        ></path>
      </g>
    </svg>
    <TextWrap>
      <span>{text}</span>
    </TextWrap>
  </MessageWrap>
);

export default NotFound;
