import styled from 'styled-components';

const ContentWrap = styled.div`
  display: block;
  flex: 1;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: ${props => props.noMargin ? 0 : '30px'};

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

export default ContentWrap