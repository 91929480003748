import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import '../../../node_modules/antd/dist/antd.css';

import FilterGrid from '../../components/FilterGrid/FilterGrid';
import AsideDrawer from '../../components/AsideDrawer/AsideDrawer';
import useFetch from '../../hooks/useFetch';

import { Sales as SalesTable } from '../../components/Tables/Sales/Sales.js';
import { API_URL, API_ROUTES } from '../../config';
import Loading from '../../components/Loading/Loading';
import { SalesStore } from '../../reducers/sales/salesReducer';
import { Button as ButtonLink, message, notification } from 'antd';
import { isEmptyObject, getTimestamp } from '../../helpers/helpers';
import { AppStore } from '../../reducers/app/appReducer';
import GLOBAL_ACTION from '../../reducers/app/actionTypes';
import ACTION from '../../reducers/sales/actionTypes';

const ContentWrap = styled.div`
  display: block;
  flex: 1;
  margin-top: 30px;
`;

const SalesGrid = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;
  grid-column-gap: 15px;

  .dx-datagrid-search-panel {
    width: 220px !important;
  }
`;

const StyledButtonLink = styled(ButtonLink)`
  padding: 0 !important;
`;

const DownloadLink = ({ link }) => (
  <StyledButtonLink className type="link">
    <a target="_blank" rel="noopener noreferrer" href={link}>
      Descargar
    </a>
  </StyledButtonLink>
);

const MESSAGE_DURATION = 0;

const SalesContainer = () => {
  const [, appDispatcher] = useContext(AppStore);
  const [state, dispatch] = useContext(SalesStore);

  const [salesReq, setSalesReq] = useState({});
  const [aggregatesReq, setAggregatesReq] = useState({});

  const [aggregates, { loading: loadingAggregates }] = useFetch(aggregatesReq);
  const [generatedTickets, { error: generateTicketsError }] = useFetch(
    state.ticketPrintReq
  );

  const [salesResponse, { url: salesReqOriginUrl }] = useFetch(salesReq);

  const handleDrawerClose = () => {
    dispatch({
      type: ACTION.DRAWER_OPEN,
      drawerOpen: false
    });

    dispatch({
      type: ACTION.CLEAR_DRAWER_DATA
    });
  };

  const rowClickHandler = selection => {
    if ('key' in selection) return;

    dispatch({
      selection,
      type: ACTION.SALE_DETAIL_DATA
    });

    if (!state.drawerOpen) {
      dispatch({
        drawerOpen: true,
        type: ACTION.DRAWER_OPEN
      });
    }
  };

  const generateLabels = items => {
    processTicketPrint(items.map(({ code }) => code));
  };

  const processTicketPrint = ids => {
    const labelCount = ids && ids.length;

    message.loading(
      `Generando ${
        labelCount === 1 ? `${labelCount} etiqueta` : `${labelCount} etiquetas`
      } de envío.`,
      MESSAGE_DURATION
    );

    dispatch({
      pushId: ids,
      type: ACTION.TICKET_PRINT_QUEUE
    });

    const printReq = {
      url: 'https://shipments.entradafan.com/api/shipments',
      body: ids,
      options: {
        method: 'POST'
      }
    };

    dispatch({
      printReq,
      type: ACTION.TICKET_PRINT_REQ
    });

    dispatch({
      isGeneratingTickets: true,
      type: ACTION.IS_GENERATING_TICKETS
    });

    dispatch({
      drawerOpen: false,
      type: ACTION.DRAWER_OPEN
    });
  };

  // Aggregates

  useEffect(() => {
    setAggregatesReq({
      url: `${API_URL}/${API_ROUTES.SALES_AGGREGATES}`
    });
  }, []);

  useEffect(() => {
    const { results } = aggregates;

    if (results) {
      dispatch({
        results,
        type: ACTION.SALES_AGGREGATES
      });

      if (state.isGeneratingTickets) {
        dispatch({
          value: 'confirmada',
          type: ACTION.STATUS_FILTER
        });

        dispatch({
          isGeneratingTickets: false,
          type: ACTION.IS_GENERATING_TICKETS
        });

        return;
      }

      const filter = (!!results.length && results[0]) || [];
      const { status } = filter;

      if (status) {
        dispatch({
          value: status,
          type: ACTION.STATUS_FILTER
        });
      }
    }
    // eslint-disable-next-line
  }, [aggregates]);

  useEffect(() => {
    const { results } = salesResponse;
    if (!results) return;

    const originUrl = new URL(salesReqOriginUrl);
    const originParameter = new URLSearchParams(originUrl.search).get('status');

    // This prevents dispatching the wrong payload when
    // switching statuses faster than the server response.
    const isSyncResponse = salesReq.params.status === originParameter;

    if (results && isSyncResponse) {
      dispatch({
        results,
        type: ACTION.STATUS_FILTERED_SALES
      });
    }

    dispatch({
      isLoading: false,
      type: ACTION.ISLOADING
    });

    // eslint-disable-next-line
  }, [salesResponse, salesReqOriginUrl]);

  useEffect(() => {
    const isFilterSet = !!state.statusFilter;

    if (isFilterSet) {
      dispatch({
        isLoading: true,
        type: ACTION.ISLOADING
      });

      setSalesReq({
        url: `${API_URL}/${API_ROUTES.SALES}`,
        params: {
          status: state.statusFilter
        }
      });
    }

    // eslint-disable-next-line
  }, [state.statusFilter]);

  useEffect(() => {
    if (generateTicketsError) {
      appDispatcher({
        fetchError: generateTicketsError,
        type: GLOBAL_ACTION.FETCH_ERRORS
      });

      message.destroy();
      return;
    }
  }, [generateTicketsError]);

  useEffect(() => {
    if (!isEmptyObject(generatedTickets)) {
      const {
        group_label_url,
        errors,
        shipments_failed,
        shipments_succeeded
      } = generatedTickets;

      const generateStatus =
        !!errors.length || !!shipments_failed || !!shipments_succeeded;
      if (generateStatus) message.destroy();

      const timestamp = getTimestamp();

      setAggregatesReq({
        url: `${API_URL}/${API_ROUTES.SALES_AGGREGATES}?s=${timestamp}`
      });

      if (shipments_succeeded) {
        const plural = shipments_succeeded > 1;
        const message = `${plural ? 'Tus etiquetas' : 'Tu etiqueta'} de envío ${
          plural ? 'estan listas para ser utilizadas' : 'esta lista para ser utilizada'
        }.`;

        notification.success({
          message,
          description: <DownloadLink link={group_label_url} />,
          duration: 0
        });
      }

      if (errors) {
        errors.map(({ code, message: errorMsg }) => {
          const args = {
            message: 'No pudimos generar tu etiqueta',
            description: `En este momento no podemos generar la etiqueta de envío de la venta ${code}. ${errorMsg}`,
            duration: 0
          };

          return notification.warning(args);
        });
      }
    }
    // eslint-disable-next-line
  }, [generatedTickets]);

  const confirmedStatus = ['confirmada', 'confirmed'].includes(state.statusFilter);
  const shippingStatus = ['shipingprocess', 'procesoenvio'].includes(state.statusFilter);
  const sentStatus = ['enviada'].includes(state.statusFilter);

  const flags = {
    confirmedStatus,
    shippingStatus,
    sentStatus
  };

  return !loadingAggregates ? (
    <ContentWrap>
      <SalesGrid>
        <FilterGrid
          activeItem={state.statusFilter}
          onItemClick={value => dispatch({ type: ACTION.STATUS_FILTER, value })}
          statusList={state.salesAggregates}
        />

        <SalesTable
          data={state.statusFilteredSales}
          flags={flags}
          isLoading={state.isLoading}
          onGenerateLabels={generateLabels}
          onRowClick={selection => rowClickHandler(selection)}
        />
      </SalesGrid>

      <AsideDrawer
        data={state.saleDetailData}
        isOpen={state.drawerOpen}
        flags={flags}
        shippingStatus={shippingStatus}
        onClose={() => handleDrawerClose()}
        onPrint={id => processTicketPrint([id])}
        confirmedStatus={confirmedStatus}
      />
    </ContentWrap>
  ) : (
    <Loading fullSize />
  );
};

export default SalesContainer;
