import { Select, Input, InputNumber } from "antd";

const bankDataInputs = [
  {
    component: Input,
    label: 'Nombre y Apellido del titular',
    name: 'account_holder_name',
    type: 'text'
  },
  {
    component: InputNumber,
    label: 'RUT',
    name: 'account_holder_document_number',
    type: 'number'
  },
  {
    component: Select,
    label: 'Banco',
    name: 'bank',
    type: 'select'
  },
  {
    component: InputNumber,
    label: 'Número de cuenta',
    name: 'account_number',
    type: 'number'
  },
  {
    component: Select,
    label: 'Tipo de cuenta',
    name: 'account_type',
    type: 'select'
  }
];

export default bankDataInputs;
