import React from 'react'
import styled from 'styled-components'
import { formatDate, DATE_PARSER } from '../../helpers/helpers'
import DS from '../../VisualStyles'

const Title = styled.div`
  font-size: 15px;
  line-height: 19px;
  font-weight: 700;
`
const Description = styled.div`
  font-size: 13px;
`
const ContentWrap = styled.div`
  color: ${DS.COLORS.SUBTITLE.SECONDARY};
  margin-bottom: ${props => props.spacing ? '20px' : '0'};
`

const HeadEvent = ({ event, spacing }) => (
  <ContentWrap spacing={spacing}>
    {event &&
      <React.Fragment>
        <Title>{event.name}</Title>
        <Description>{event.date ? formatDate({ date: event.date, style: DATE_PARSER.LONG_DATE_TIME }) : ''}</Description>
      </React.Fragment>
    }
  </ContentWrap>
)

export default HeadEvent