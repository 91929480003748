import React from 'react';
import styled from 'styled-components';
import { Ticket } from '../Icons/Icons';
import { Badge } from 'antd';

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 0 15px;
`;
const Description = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 10px;

  span {
    text-align: left;
  }
`;
const FilterItem = styled.div`
  align-items: center;
  background-color: ${props => (props.isActive ? '#edf4ff' : 'transparent')};
  border-color: ${props => (props.isActive ? '#6495ed' : '#f3f4f8')};
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  color: ${props => (props.isActive ? '#6495ed' : 'inherit')};
  cursor: pointer;
  flex-direction: column;
  font-size: 11px;
  font-weight: 600;
  justify-content: center;
  margin-bottom: 15px;
  padding: 8px;
  text-align: center;
  transition: all 200ms;
  user-select: none;

  &:hover {
    background-color: ${props => !props.isActive && '#f3f4f8'};
  }
`;
const Incomes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .price-display {
    margin-bottom: 5px;
    text-align: left;
  }
  .ticket-count {
    display: flex;

    .count {
      margin-right: 5px;
    }
  }
`;

const badgeStyles = {
  backgroundColor: '#1890ff',
  color: '#fff',
  fontWeight: 700,
  marginRight: '10px'
};

const FilterGrid = ({ activeItem, onItemClick, statusList }) => (
  <>
    {statusList && (
      <Grid>
        {statusList.map(({ status, description, sales, receive, tickets }) => (
          <FilterItem
            isActive={activeItem === status}
            key={[status, description].join('|')}
            onClick={() => onItemClick(status)}
          >
            <Description>
              <Badge count={sales} overflowCount={10000} style={{ ...badgeStyles }} />
              <span>{description}</span>
            </Description>
            <Incomes>
              <div className="price-display">{receive.display}</div>
              <div className="ticket-count">
                <div className="count">{tickets}</div>
                <Ticket />
              </div>
            </Incomes>
          </FilterItem>
        ))}
      </Grid>
    )}
  </>
);

export default FilterGrid;
