const prepearePublication = ({ constraints, event, values }) => {
  const { row, section, split_type, ticket_price, ticket_receive, tickets } = values;
  const locations = constraints && constraints.locations.find(({ id }) => id === values.location);
  const sections = locations && locations.sections.find(({ id }) => id === section);

  const locationSection = section && {
    id: section.id
  }

  const location = {
    id: values.location,
    row,
    section: section
  };

  const eventId = event && event.id.replace(/\D/g, '');

  return {
    date_in_hand: '2020-01-01T00:00:00.0Z',
    event: {
      id: eventId
    },
    location,
    split_type,
    status: 'active',
    ticket_type: 'paperticket',
    tickets,
    ticket_price: {
      amount: ticket_price,
      currency_code: 'ARS'
    },
    ticket_receive: {
      amount: ticket_receive,
      currency_code: 'ARS'
    }
  };
};

export { prepearePublication };
