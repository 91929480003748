const COLOR_PALETTE = {
  BRAND: {
    MAIN: {
      50: '#e1f1fe',
      100: '#b7dcfe',
      200: '#88c7ff',
      300: '#53b1fe',
      400: '#1ca0ff',
      500: '#0090fe',
      600: '#0082f0',
      700: '#0070dc',
      800: '#005fca',
      900: '#0040a9'
    },
    SECONDARY: {
      50: '#fbfbfb',
      100: '#f6f6f6',
      200: '#f1f1f1',
      300: '#e5e5e5',
      400: '#c2c2c2',
      500: '#a4a4a4',
      600: '#7a7a7a',
      700: '#666666',
      800: '#474747',
      900: '#252525'
    }
  }
}

export { COLOR_PALETTE }