import React from 'react';
import styled from 'styled-components';
import algoliasearch from 'algoliasearch/lite';
import {
  connectHits,
  connectSearchBox,
  InstantSearch,
  Index,
  Configure
} from 'react-instantsearch-dom';
import { SearchIcon } from './SearchIcon';

const SearchRoot = styled.div`
  position: relative;
`;
const SearchWrap = styled.label`
  align-items: center;
  background-color: #fff;
  border: ${props => (props.size.height ? 'none' : 'solid 1px #c0d0e1')};
  border-radius: ${props => (props.size.height ? '8px' : '3px')};
  box-shadow: ${props => (props.size.height ? '0 2px 4px 0 rgba(0, 0, 0, 0.5)' : 'none')};
  display: flex;
  font-size: ${props => props.size.fontSize || '16px'};
  height: ${props => props.size.height || '37px'};
  padding-left: 15px;
  padding-right: 5px;
  width: ${props => props.size.width || '100%'};
  ${props => props.isActive && 'border-bottom-left-radius: 0'};
  ${props => props.isActive && 'border-bottom-right-radius: 0'};
`;
const Input = styled.input`
  border: none;
  color: #1f2a36;
  font-size: inherit;
  height: 100%;
  margin-left: 12px;
  outline: none;
  width: 100%;
`;
const ResultList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
`;
const ResultItem = styled.li`
  color: #4d5a69;
  cursor: pointer;
  font-size: 15px;
  line-height: 19px;
  list-style: none;
  margin: 9px 0;
  position: relative;

  &:hover {
    &:before {
      background-color: #2b61a0;
      border-radius: 8px;
      content: '';
      height: 125%;
      left: -10%;
      position: absolute;
      top: -5px;
      width: 120%;
      z-index: -1;
    }
    color: #fff;
  }
`;
const ResultDate = styled.span`
  display: block;
  font-size: 13px;
  line-height: 18px;
`;
const ResultTitle = styled.span`
  display: block;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 5px;
`;
const ResultsWrap = styled.div`
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 10;
`;
const ResultsInner = styled.div`
  margin-top: 20px;
`;
const SearchBoxComponent = ({ active, refine, currentRefinement, size = {} }) => (
  <SearchWrap htmlFor="searchInput" size={size} isActive={active}>
    <SearchIcon />
    <Input
      autoComplete="off"
      id="searchInput"
      onChange={e => refine(e.currentTarget.value)}
      placeholder="Buscar por evento, nombre, ubicación o país"
      type="search"
      value={currentRefinement}
    />
  </SearchWrap>
);

const HitComponent = ({ hits, onEventSelect }) => (
  <React.Fragment>
    {!!hits.length && (
      <React.Fragment>
        <ResultList>
          {hits.map(({ nombre, objectID, fechalarga, fechaiso8601 }) => (
            <div
              key={objectID}
              onClick={() =>
                onEventSelect({
                  date: fechaiso8601,
                  parsedDate: fechalarga,
                  id: objectID,
                  name: nombre
                })
              }
            >
              <ResultItem>
                <ResultTitle>{nombre}</ResultTitle>
                <ResultDate>{fechalarga}</ResultDate>
              </ResultItem>
            </div>
          ))}
        </ResultList>
      </React.Fragment>
    )}
  </React.Fragment>
);

const EventSearch = ({ sizes, onEventSelect }) => {
  const searchClient = algoliasearch('C85QYNMXTB', '2c26c2d41acf167167cefcfe97e99f9e');

  return (
    <SearchRoot>
      <InstantSearch indexName="eventosAR" searchClient={searchClient}>
        <Configure hitsPerPage={10} />

        <SearchInput size={sizes} />

        <ResultsWrap>
          <ResultsInner>
            <Index indexName="eventosAR">
              <CustomHits title="Eventos" onEventSelect={onEventSelect} />
            </Index>
          </ResultsInner>
        </ResultsWrap>
      </InstantSearch>
    </SearchRoot>
  );
};

const CustomHits = connectHits(HitComponent);
const SearchInput = connectSearchBox(SearchBoxComponent);
export default EventSearch;
