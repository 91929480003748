import React, { useContext } from 'react';
import { AppStore } from '../../reducers/app/appReducer';
import { useEffect } from 'react';
import { notification } from 'antd';
import styled from 'styled-components';

const Content = styled.div`
  .ant-table-wrapper {
    flex: 1;
  }
`;

const AppContainer = ({ children }) => {
  const [state] = useContext(AppStore);

  useEffect(() => {
    const { fetchErrors } = state;

    if (fetchErrors) {
      notification.error({
        message: 'No se pudo procesar tu pedido en este momento.'
      });
    }
  }, [state.fetchErrors]);

  return <Content>{children}</Content>;
};

export default AppContainer;
