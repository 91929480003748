export const API_URL = 'https://api.entradafan.com/v1'
// export const API_URL = 'https://entradafanapi.azurewebsites.net/v1'

export const API_ROUTES = {
  USER: 'user',
  BANK_TYPES: 'banks',
  USER_PAYOUT_DATA: 'user/payout',
  BANK_ACCOUNT_TYPES: 'bankaccounttypes',
  IDENTITY_TYPES: 'documenttypes',
  COMPARE_LISTINGS: 'marketlistings',
  EVENTS: 'events',
  LISTING_AGGREGATES: 'sellerlistingsaggregates',
  LISTINGS: 'sellerlistings',
  ORDERS: 'purchases',
  REPORTS: 'reports',
  SALES_AGGREGATES: 'salesaggregates',
  SALES: 'sales'
}
