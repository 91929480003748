import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';

import { API_URL, API_ROUTES } from '../../config';
import ACTION from '../../reducers/listings/actionTypes';
import { ListingsStore } from '../../reducers/listings/listingsReducer';
import useFetch from '../../hooks/useFetch';

import { Listings as ListingsTable } from '../../components/Tables/Listings/Listings.js';
import PublicationContainer from './PublicationContainer';
import { PublicationProvider } from '../../reducers/publication/publicationReducer';
import CompareListings from '../../components/Tables/CompareListings/CompareListings';
import Loading from '../../components/Loading/Loading';

const ContentWrap = styled.div`
  display: block;
  flex: 1;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 30px;

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const url = `${API_URL}/${API_ROUTES.LISTING_AGGREGATES}`;

const req = {
  avoidCache: true,
  url,
  params: {
    parent_code: 'EVENT'
  }
};

const ListingsContainer = () => {
  const [state, dispatch] = useContext(ListingsStore);

  const [listingsResponse, { loading: loadingListings }] = useFetch(req);
  useFetch(state.setActiveListingReq);
  useFetch(state.batchManageListingsReq);

  const handleOnCreate = () => {
    dispatch({
      type: ACTION.OPEN_PUBLICATION_DRAWER
    });

    dispatch({
      type: ACTION.RESET_EDIT_LISTING
    });
  };

  useEffect(() => {
    const { results } = listingsResponse;

    if (results) {
      dispatch({
        type: ACTION.SET_EVENT_LISTINGS,
        payload: results
      });
    }
    // eslint-disable-next-line
  }, [listingsResponse]);

  return (
    <ContentWrap>
      {!loadingListings ? (
        <PublicationProvider>
          <ListingsTable data={state.eventListings} onCreateClick={handleOnCreate} />
          <PublicationContainer onClose={handleOnCreate} />
        </PublicationProvider>
      ) : (
        <Loading fullSize />
      )}
      <CompareListings
        isOpen={state.comparingActive}
        data={state.compareListingData}
        onClose={() => dispatch({ type: ACTION.COMPARE_ACTIVE })}
      />
    </ContentWrap>
  );
};

export default ListingsContainer;
