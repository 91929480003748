import React from 'react';

export const Ticket = () => (
  <svg
    className="ticket"
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="16"
    viewBox="0 0 11 16"
    id="small-grey-ticket"
    y="2927"
  >
    <path
      d="M10.387-.002H7.623a2.277 2.277 0 0 1-2.14 1.508c-.986 0-1.824-.63-2.14-1.508H.58C.247-.002 0 .44 0 .773v14.454c0 .333.247.773.58.773h2.763a2.274 2.274 0 0 1 2.14-1.508c.986 0 1.824.63 2.14 1.508h2.764a.605.605 0 0 0 .603-.604V.602a.605.605 0 0 0-.603-.604z"
      fill="#C5CCD0"
      fillRule="evenodd"
    />
  </svg>
);

export const LogoTickets = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
  >
    <path
      fill="#fff"
      d="M112.6 63.2c-.4 1.1-1.6 1.7-2.8 1.3-1.1-.4-1.7-1.6-1.3-2.8.4-1.1 1.7-1.7 2.8-1.3 1.1.5 1.7 1.7 1.3 2.8m-7.5-2.7c-.4 1.1-1.6 1.7-2.7 1.3-1.1-.4-1.7-1.6-1.3-2.7V59c.4-1.1 1.6-1.7 2.8-1.3 1 .4 1.6 1.7 1.2 2.8m17.3-16l-12.6-4.6c-2.1 2.6-5.7 3.6-9 2.4-3.3-1.2-5.3-4.3-5.3-7.7 0 0-6.6-2.4-7.6-2.8-1-.4-1.8-.5-1.2 1l19.2 52.8c1.5 4 2.5 3.7 3.8.3l14.1-38.8c.3-.9-.3-2.2-1.4-2.6M81.9 59.1c-1.2.8-2.9.5-3.8-.7-.3-.4-.4-.8-.5-1.3-.1-1 .3-1.9 1.2-2.5 1.2-.8 2.9-.5 3.8.7.3.4.4.8.5 1.2 0 1.1-.4 2-1.2 2.6m-9.5 3.4c-1.2.8-2.9.5-3.8-.7-.3-.4-.4-.8-.5-1.3-.1-1 .3-1.9 1.2-2.5 1.2-.8 2.9-.5 3.8.7.3.4.4.8.5 1.2.1 1.1-.4 2.1-1.2 2.6M63 66c-1.2.8-2.9.5-3.8-.7-.3-.4-.4-.8-.5-1.3-.1-1 .3-1.9 1.2-2.5 1.2-.8 2.9-.5 3.8.7.3.4.4.8.5 1.2 0 1.1-.4 2-1.2 2.6m-9.5 3.4c-1.2.8-2.9.5-3.8-.7-.3-.4-.4-.8-.5-1.3-.1-1 .3-1.9 1.2-2.5 1.2-.8 2.9-.5 3.8.7.3.4.4.8.5 1.2.1 1.1-.3 2-1.2 2.6m-9.4 3.4c-1.2.8-2.9.5-3.8-.7-.3-.4-.4-.8-.5-1.3-.1-1 .3-1.9 1.2-2.5 1.2-.8 2.9-.5 3.8.7.3.4.4.8.5 1.2.1 1.1-.4 2.1-1.2 2.6m33.2-42.6l-15.8 5.7c0 4.2-2.5 8.1-6.7 9.6-4.1 1.5-8.6.1-11.3-3l-15.8 5.7c-1.4.5-2.1 2.1-1.6 3.5l24.1 66.1c.5 1.4 2.1 2.1 3.5 1.6l15.8-5.7c0-4.2 2.5-8.1 6.7-9.6 4.1-1.5 8.6-.1 11.3 3l15.8-5.7c1.4-.5 2.1-2.1 1.6-3.4L80.7 31.8c-.5-1.4-2-2.1-3.4-1.6"
    ></path>
  </svg>
);
