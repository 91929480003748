import React, { useReducer } from 'react';
import ACTION from './actionTypes';

export const SalesStore = React.createContext('');

const initialState = {
  drawerOpen: false,
  statusFilteredSales: null,
  isLoading: false,
  isGeneratingTickets: false,
  primaryPlatformLink: null,
  saleAdditionalDataReq: {},
  saleAdditionalData: null,
  saleDetailData: {},
  salesAggregates: null,
  statusFilter: null,
  ticketPrintQueue: [],
  ticketPrintReq: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.SALE_DETAIL_DATA:
      return {
        ...state,
        saleDetailData: action.selection
      };

    case ACTION.SALE_ADDITIONAL_DATA_REQ:
      return {
        ...state,
        saleAdditionalDataReq: action.req
      };

    case ACTION.SALE_ADDITIONAL_DATA:
      return {
        ...state,
        saleAdditionalData: action.additionalData
      };

    case ACTION.CLEAR_DRAWER_DATA:
      return {
        ...state,
        saleAdditionalDataReq: {},
        saleAdditionalData: null
      };

    case ACTION.DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.drawerOpen
      };

    case ACTION.STATUS_FILTERED_SALES:
      return {
        ...state,
        statusFilteredSales: action.results
      };

    case ACTION.ISLOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case ACTION.SALES_AGGREGATES:
      return {
        ...state,
        salesAggregates: action.results
      };

    case ACTION.STATUS_FILTER:
      return {
        ...state,
        statusFilter: action.value
      };

    case ACTION.IS_GENERATING_TICKETS: {
      return {
        ...state,
        isGeneratingTickets: action.isGeneratingTickets
      };
    }

    case ACTION.TICKET_PRINT_QUEUE:
      return {
        ...state,
        ticketPrintQueue: [...state.ticketPrintQueue, ...action.pushId]
      };

    case ACTION.TICKET_PRINT_REQ:
      return {
        ...state,
        ticketPrintReq: action.printReq
      };

    case ACTION.PRIMARY_PLATFORM_LINK:
      return {
        ...state,
        primaryPlatformLink: action.primaryPlatformLink
      };

    default:
      return state;
  }
};

export function SalesProvider(props) {
  return (
    <SalesStore.Provider value={useReducer(reducer, initialState)}>
      {props.children}
    </SalesStore.Provider>
  );
}
