import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
import './index.css';
import 'antd/dist/antd.css';
import { Auth0Provider } from './react-auth0-spa';
import * as Sentry from '@sentry/browser';
import TagManager from 'react-gtm-module';
import config from './authConfig.json';
import history from './helpers/history';
import * as serviceWorker from './serviceWorker';

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  );
};

if (process.env.NODE_ENV !== 'development') {
  TagManager.initialize({ gtmId: 'GTM-5L5WT66' });
  Sentry.init({ dsn: 'https://61e380bb18a74bd4b03d2f8d3884d5d4@sentry.io/1895504' });
}

const getTld = url => {
  const splitUrl = url
    .split(/\./)
    .slice(-2)
    .join('.');
  const preparedUrl = splitUrl.replace('/', '').split('.');

  const [, tld] = preparedUrl;
  const allowedTlds = ['ar', 'br', 'co', 'cl', 'mx', 'pe', 'uy'];

  return allowedTlds.includes(tld) ? tld.toUpperCase() : 'AR';
};

const tld = getTld(window.location.host);

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={process.env[`REACT_APP_CLIENT_ID_ACCOUNT_${tld}`]}
    audience={config.audience}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
