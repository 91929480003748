import React, { useContext, useEffect } from 'react';
import { Drawer, message } from 'antd';
import DataGrid, { Column, Paging, Pager, RowDragging } from 'devextreme-react/data-grid';
import columns from './tableConfig';
import Loading from '../../Loading/Loading';
import DS from '../../../VisualStyles';
import update from 'immutability-helper';
import { ListingsStore } from '../../../reducers/listings/listingsReducer';
import ACTION from '../../../reducers/listings/actionTypes';
import { API_ROUTES, API_URL } from '../../../config';
import useFetch from '../../../hooks/useFetch';
import { isEmptyObject } from '../../../helpers/helpers';

const CompareListings = ({ isOpen, data, onClose }) => {
  const [state, dispatch] = useContext(ListingsStore);
  const [marketListings] = useFetch(state.compareListingReq);
  const [updateResponse, { loading, error }] = useFetch(state.updateListingPriceReq);

  const updateNewPrice = ({ code, amount }) => {
    const req = {
      body: {
        ticket_price: { amount }
      },
      url: `${API_URL}/${API_ROUTES.LISTINGS}/${code}`,
      options: {
        method: 'PATCH'
      }
    };

    dispatch({
      req,
      type: ACTION.UPDATE_LISTING_PRICE_REQ
    });
  };

  const onCellPrepared = e => {
    if (e.rowType === 'data') {
      if (e.data.listing_seller) {
        e.cellElement.style.backgroundColor = DS.COLORS.ICON.INTERACTIVE;
        e.cellElement.style.color = '#fff';
      } else {
        e.cellElement.style.pointerEvents = 'none';
      }
    }
  };

  const onReorder = e => {
    const currentDrag = e.itemData;
    let visibleRows = e.component.getVisibleRows(),
      toIndex = data.indexOf(visibleRows[e.toIndex].data),
      fromIndex = data.indexOf(currentDrag);

    const targetDragPrice = data[toIndex].ticket_price.amount;
    const upscalePrice = currentDrag.ticket_price.amount < targetDragPrice;
    const newAmount = upscalePrice ? targetDragPrice + 1 : targetDragPrice - 1;

    const updateCurrentDrag = {
      ...e.itemData,
      ticket_price: {
        ...e.itemData.ticket_price,
        display: `$ ${newAmount}`,
        amount: newAmount
      }
    };

    updateNewPrice({ code: currentDrag.code, amount: newAmount });

    const newData = update(data, {
      $splice: [
        [fromIndex, 1],
        [toIndex, 0, updateCurrentDrag]
      ]
    });

    dispatch({
      compareListingData: newData,
      type: ACTION.COMPARE_LISTING_DATA
    });

    e.component.refresh();
  };

  useEffect(() => {
    const { results } = marketListings;

    if (results) {
      dispatch({
        compareListingData: results,
        type: ACTION.COMPARE_LISTING_DATA
      });
    }
  }, [marketListings]);

  useEffect(() => {
    const key = 'updateMessage'
    if (loading) {
      message.loading({ content: 'Actualizando publicación', key });
    }
    if (!error && !loading && !isEmptyObject(updateResponse)) {
      message.success({
        content: 'El precio de tu publicación fue actualizado.',
        key
      });
    }
  }, [updateResponse, loading, error]);

  return (
    <React.Fragment>
      <Drawer
        onClose={onClose}
        title="Publicaciones similares"
        visible={isOpen}
        width={520}
      >
        {state.compareListingData ? (
          <DataGrid
            allowColumnResizing={true}
            autoNavigateToFocusedRow
            columnAutoWidth
            dataSource={data}
            onCellPrepared={onCellPrepared}
            noDataText="No se encontraron resultados."
            showColumnLines={false}
            showRowLines
          >
            <RowDragging allowReordering={true} onReorder={onReorder} showDragIcons />

            {columns.map(props => (
              <Column key={Object.values(props).join('|')} {...props} />
            ))}
            <Paging defaultPageSize={100} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} />
          </DataGrid>
        ) : (
          <Loading />
        )}
      </Drawer>
    </React.Fragment>
  );
};

export default CompareListings;
