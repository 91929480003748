import React from 'react'
import styled from 'styled-components'
import DS from '../../../VisualStyles'

const Code = styled.div`
  color: ${DS.COLORS.TITLE.PRIMARY};
  font-size: 24px;
`
const GroupTitle = styled.div`
  color: ${DS.COLORS.TITLE.SECONDARY};
  font-size: 14px;
  user-select: none;
`

const DrawerHead = ({ code }) => (
  <>
    <GroupTitle>Operación</GroupTitle>
    <Code>{code}</Code>
  </>
)

export default DrawerHead