import React from 'react'
import styled from 'styled-components';
import { ReactComponent as Visa } from 'payment-icons/min/flat/visa.svg';
import { ReactComponent as Amex } from 'payment-icons/min/flat/amex.svg';
import { ReactComponent as MasterCard } from 'payment-icons/min/flat/mastercard.svg';
import { ReactComponent as Rapipago } from '../Icons/rapipago.svg';

const cards = {
  amex: Amex,
  cabal: 'cabal',
  master: MasterCard,
  rapipago: Rapipago,
  visa: Visa
}

const CardWrap = styled.div`
  align-items: center;
  display: flex;

  svg {
    height: 30px;
    width: 40px;
  }
`

const PaymentMethod = ({ method }) => {
  const Card = method in cards && cards[method];

  return Card ? (
    <CardWrap>
      <Card />
    </CardWrap>
  ) : null;
}

export default PaymentMethod;