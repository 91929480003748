import React from 'react';
import styled from 'styled-components';
import columns from './tableConfig';
import { Button } from 'antd';
import DataGrid, {
  Column,
  Grouping,
  MasterDetail,
  Pager,
  Paging,
  Scrolling,
  SearchPanel
} from 'devextreme-react/data-grid';
import ListingsDetailContainer from '../../../containers/listings/ListingDetailContainer';
import NotFound from '../../EmptyState/NotFound';
import './styles.css'

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`;

const Listings = ({ data, loading, onCreateClick }) => {
  const dataAvailable = data.length > 0;

  return (
    <React.Fragment>
      <Header>
        <Button type='primary' onClick={onCreateClick}>
          Crear publicación
        </Button>
      </Header>

      {dataAvailable &&
        <DataGrid
          allowColumnResizing={true}
          columnAutoWidth
          dataSource={data}
          noDataText="No se encontraron resultados."
          showColumnLines={false}
          showRowLines
        >
          <SearchPanel
            highlightCaseSensitive={true}
            placeholder={'Buscar por evento'}
            visible={true}
            width={300}
          />
          <MasterDetail
            autoExpandAll={false}
            enabled={true}
            render={data => <ListingsDetailContainer {...data} />}
          />
          <Grouping autoExpandAll={true} />
          <Scrolling columnRenderingMode={'virtual'} />

          {columns.map(props => (
            <Column key={Object.values(props).join('|')} {...props} />
          ))}

          <Paging defaultPageSize={20} />
          <Pager showPageSizeSelector={true} allowedPageSizes={[20, 50, 100]} />
        </DataGrid>
      }

      {!dataAvailable &&
        <NotFound text="No tenés publicaciones activas." />
      }
    </React.Fragment>
  );
};

export { Listings };
