import React from 'react';

const Location = ({ location }) => (
  <React.Fragment>
    {location.name && location.name}
    {location.row && ` - Fila: ${location.row}`}
  </React.Fragment>
);

const columns = [
  {
    allowFiltering: false,
    allowUpdating: false,
    caption: 'Ubicación',
    dataField: 'location',
    cellRender: ({ data }) => <Location location={data.location} />
  },
  {
    alignment: 'center',
    allowEditing: true,
    allowUpdating: false,
    caption: 'Cantidad',
    dataField: 'tickets'
  },
  {
    allowFiltering: false,
    allowUpdating: false,
    caption: 'Precio de venta',
    dataField: 'ticket_price.display'
  }
];

export default columns;
