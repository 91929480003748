import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Img from 'react-image';
import ContentWrap from '../ContentWrap/ContentWrap';
import { formatDate, DATE_PARSER } from '../../helpers/helpers';
import { Button, Tag, Divider } from 'antd';
import { OrderStore } from '../../reducers/orders/ordersReducer';
import ACTION from '../../reducers/orders/actionTypes';
import { API_ROUTES, API_URL } from '../../config';
import useFetch from '../../hooks/useFetch';
import PaymentMethod from './PaymentMethod';
import { ORDER_STATUSES } from './orderStatuses';

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 360px 1fr;
  grid-column-gap: 50px;
  margin-top: 20px;
`;
const LeftPane = styled.div`
  .event-title {
    color: #525252;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 5px;
  }
  .event-date,
  .event-venue {
    color: #6f6f6f;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
`;
const NoImageWrap = styled.div`
  align-items: center;
  background-color: #f3f4f8;
  border-radius: 6px;
  display: flex;
  font-size: 12px;
  justify-content: center;
  height: 210px;
  margin-bottom: 15px;
  text-transform: uppercase;
  width: 100%;
`;

const OrderDetailWrap = styled.div`
  align-items: center;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.015), 0 0px 4px rgba(0, 0, 0, 0.015),
    0 0px 8px rgba(0, 0, 0, 0.015), 0 0px 16px rgba(0, 0, 0, 0.015),
    0 0px 32px rgba(0, 0, 0, 0.015), 0 0px 64px rgba(0, 0, 0, 0.015);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 25px;
  overflow: hidden;

  .data {
    width: 100%;
  }

  .row-flex {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .group-title {
    font-size: 12px;
    font-weight: 600;
    margin-right: 5px;
    text-transform: uppercase;
  }

  .item-title {
    font-weight: 400;
  }

  .order-status {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .order-number {
    font-size: 20px;
    font-weight: 600;
  }

  .order-payment {
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-column-gap: 10px;
    align-items: center;

    &__card-number {
      align-items: center;
      display: flex;

      &:before {
        content: '●●●●';
        font-size: 9px;
        margin-right: 3px;
      }
    }
  }

  .price-total {
    font-size: 20px;
  }

  .location {
    font-size: 20px;
  }
`;

const AdditionalData = styled.div`
  display: grid;
  grid-auto-rows: 30px;
  grid-row-gap: 10px;
`;

const StyledButton = styled(Button)`
  padding: 0;
`;

const StyledTag = styled(Tag)`
  margin: 0 !important;
`;

const Division = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  margin-top: 30px;
`;

const OrderDetail = ({ order, onNavBack }) => {
  const [state, dispatch] = useContext(OrderStore);
  const {
    code,
    created_at,
    delivery_method,
    event,
    location,
    payments,
    shipment,
    shipping_date,
    status,
    tickets_quantity,
    total_amount
  } = order;
  const isImageEmpty = !![...event.image].length;
  const [orderDetail] = useFetch(state.getOrderReq);

  useEffect(() => {
    const req = {
      url: `${API_URL}/${API_ROUTES.ORDERS}/${code}`
    };

    dispatch({
      req,
      type: ACTION.GET_ORDER_REQ
    });
  }, []);

  return (
    <ContentWrap>
      <StyledButton className type="link" icon="arrow-left" onClick={onNavBack}>
        Volver a mis compras
      </StyledButton>
      <ContentGrid>
        <LeftPane>
          {!isImageEmpty && <NoImageWrap>Imagen no disponible</NoImageWrap>}
          {isImageEmpty && <Img src={event.image} unloader />}
          {event.name && <h1 className="event-title">{event.name}</h1>}
          {event.date && (
            <div className="event-date">
              {formatDate({ date: event.date, style: DATE_PARSER.DATE_YEAR_TIME })}
            </div>
          )}
          {event.venue && <div className="event-venue">{event.venue.name}</div>}
          {<div className="additional-data"></div>}
        </LeftPane>
        <div>
          <OrderDetailWrap>
            <div className="data">
              <div className="order-status">
                <div className="order-number">{code}</div>
                <StyledTag color={ORDER_STATUSES[status].color}>
                  {ORDER_STATUSES[status].name}
                </StyledTag>
              </div>

              <div className="location">
                {`${tickets_quantity} x ${location.name}${
                  location.row ? ` - Fila: ${location.row}` : ''
                }`}
              </div>

              {payments && (
                <React.Fragment>
                  <Divider />
                  <Division>
                    <div className="group-title">Pago</div>
                  </Division>
                  <AdditionalData>
                    <div>
                      {payments.map(({ card, method_id, status }) => (
                        <div className="order-payment">
                          <div className="item-title">Método de pago</div>
                          <PaymentMethod method={method_id} />

                          {card && (
                            <div className="order-payment__card-number">
                              {card.last_four_digits}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>

                    {created_at && (
                      <div className="row-flex">
                        <div className="item-title">Fecha de compra</div>
                        <div>
                          {formatDate({
                            date: created_at,
                            style: DATE_PARSER.ABBREVIATED
                          })}
                        </div>
                      </div>
                    )}
                  </AdditionalData>
                </React.Fragment>
              )}

              {shipment && (
                <React.Fragment>
                  <Division>
                    <div className="group-title">Entrega</div>
                  </Division>
                  <AdditionalData>
                    <div className="row-flex">
                      <div className="item-title">Método de envío</div>
                      <div>{delivery_method.name}</div>
                    </div>

                    {shipping_date && (
                      <div className="row-flex">
                        <div className="item-title shipping__date">Fecha de envío</div>
                        <div>
                          {formatDate({
                            date: shipping_date,
                            style: DATE_PARSER.ABBREVIATED
                          })}
                        </div>
                      </div>
                    )}
                  </AdditionalData>
                </React.Fragment>
              )}

              {total_amount && (
                <React.Fragment>
                  <Divider dashed />
                  <div className="row-flex price-total">
                    <div className="item-title">Importe</div>
                    <div>{total_amount.display}</div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </OrderDetailWrap>
        </div>
      </ContentGrid>
    </ContentWrap>
  );
};

export default OrderDetail;
