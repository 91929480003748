import React from 'react';
import styled from 'styled-components';
import { LogoTickets } from '../Icons/Icons';

const Wrap = styled.div`
  background-color: #dde1e6;
  border-radius: 4px;
`;

const NoImage = () => (
  <Wrap>
    <LogoTickets />
  </Wrap>
);

export default NoImage;