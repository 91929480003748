export const ORDER_STATUSES = {
  issue: {
    color: 'error',
    name: 'En revisión'
  },
  rejected: {
    color: 'orange',
    name: 'Rechazada'
  },
  cancelled: {
    color: 'magenta',
    name: 'Cancelada'
  },
  confirmed: {
    color: 'processing',
    name: 'Confirmada'
  },
  delivered: {
    color: 'green',
    name: 'Entregada'
  },
  pending: {
    color: 'purple',
    name: 'Pendiente'
  },
  shipped: {
    color: 'green',
    name: 'Enviada'
  },
  paid: {
    color: 'green',
    name: 'Pagada'
  },
};
