import React from 'react'
import styled from 'styled-components'
import { Icon } from 'antd';
import DS from '../../VisualStyles';

const LoadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => props.fullSize ? '100vh' : '100%'};
  width: 100%;
`
const Loading = ({ fullSize }) => (
  <LoadingWrap fullSize={fullSize}>
    <Icon type='loading' style={{ color: DS.COLORS.ICON.INTERACTIVE, fontSize: 48 }} spin />
  </LoadingWrap>
)

export default Loading