import React, { useContext } from 'react';
import { Drawer } from 'antd';
import { PublicationStore } from '../../reducers/publication/publicationReducer';
import ACTION from '../../reducers/publication/actionTypes';
import EventSearch from './EventSearch';
import PublicationForm from '../PublicationForm/PublicationForm';
import publicationInputs from '../../containers/listings/publicationInputs';

const NewPublication = ({ onSubmit, isOpen, loading, onClose }) => {
  const [state, dispatch] = useContext(PublicationStore);
  const { eventConstraints } = state;

  const handleEventSelect = event => {
    dispatch({
      type: ACTION.STEP_FORWARD
    });

    dispatch({
      event,
      type: ACTION.SELECTED_EVENT
    });
  };

  return (
    <Drawer
      maskClosable
      onClose={onClose}
      title={
        state.step === 1
          ? 'Crear nueva publicación'
          : `${state.selectedEvent.name} - ${state.selectedEvent.parsedDate}`
      }
      visible={isOpen}
      width={520}
    >
      {isOpen && state.step === 1 && <EventSearch onEventSelect={handleEventSelect} />}
      {state.step === 2 && (
        <PublicationForm
          constraints={eventConstraints}
          form={publicationInputs}
          event={state.selectedEvent}
          onClose={onClose}
          loading={loading}
          onFormSubmit={onSubmit}
        />
      )}
    </Drawer>
  );
};

export default NewPublication;
