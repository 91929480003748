import React from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import columns from './tableConfig';
import './styles.css'

const ListingsDetail = ({ dataSource }) => (
  <React.Fragment>
    <DataGrid
      allowColumnResizing={true}
      columnAutoWidth
      dataSource={dataSource}
      reshapeOnPush
      showColumnLines={false}
    >
      {columns.map(props => (
        <Column key={Object.values(props).join('|')} {...props} />
      ))}
    </DataGrid>
  </React.Fragment>
);

export default ListingsDetail;
