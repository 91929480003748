import { Input, Select, InputNumber } from 'antd';

const publicationInputs = [
  {
    component: Select,
    label: 'Ubicación',
    name: 'location'
  },
  {
    component: Select,
    label: 'Sector',
    name: 'section'
  },
  {
    component: Input,
    label: 'Fila',
    name: 'row',
    type: 'text'
  },
  {
    component: InputNumber,
    label: 'Precio de venta',
    name: 'ticket_price',
    type: 'number'
  },
  {
    component: InputNumber,
    label: 'A recibir',
    name: 'ticket_receive',
    type: 'number'
  },
  {
    component: InputNumber,
    label: 'Cantidad',
    name: 'tickets',
    type: 'number'
  },
  {
    component: Select,
    label: 'Modo de venta',
    name: 'split_type'
  }
];

export default publicationInputs;
