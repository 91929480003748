import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Form, Select, Input, Avatar } from 'antd';
import useForm from '../../hooks/useForm';
import { AccountStore } from '../../reducers/account/accountReducer';
import accountInputs from '../../containers/account/profileFormMappings';
import ACTION from '../../reducers/account/actionTypes';
import { isEmptyObject } from '../../helpers/helpers';
import Loading from '../Loading/Loading';
import useFetch from '../../hooks/useFetch';

const { Item } = Form;
const { Option } = Select;

const ProfileGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 140px;
  grid-column-gap: 45px;
  width: 100%;
`;
const StyledForm = styled(Form)`
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    'first_name first_name first_name last_name last_name last_name'
    'document_type document_number document_number . . .'
    'address address zip_code . . .'
    'city city state state . . '
    'phone_number phone_number . . . .';
`;
const StyledItem = styled(Item)`
  grid-area: ${props => props.area};
`;

const UserActions = styled.div`
  align-items: flex-end;
  display: flex;
`;

const Profile = ({ isSubmitting, onSubmit }) => {
  const [state, dispatch] = useContext(AccountStore);
  const { addValue, handleChange, values } = useForm();
  const [identityTypes, { loading: loadingIdTypes }] = useFetch(state.identityTypesReq);

  useEffect(() => {
    const { profileInputs, userData } = state;

    if (profileInputs && userData) {
      profileInputs.forEach(({ name }) => {
        addValue({ name, value: userData[name] });
      });
    }
  }, []);

  useEffect(() => {
    const { userData } = state;

    if (userData) {
      const enrichedProfileInputs = Object.keys(userData).map(key => {
        const matchingInput = accountInputs.find(({ name }) => name === key);

        addValue({ name: key, value: userData[key] });

        return matchingInput;
      });

      dispatch({
        profileInputs: enrichedProfileInputs,
        type: ACTION.PROFILE_INPUTS
      });
    }
    // eslint-disable-next-line
  }, [state.userData]);

  useEffect(() => {
    if (!isEmptyObject(identityTypes)) {
      const { results } = identityTypes;

      dispatch({
        identityTypes: results,
        type: ACTION.IDENTITY_TYPES
      });
    }
  }, [identityTypes]);

  const handleDocumentSelect = selectionId => {
    const type = state.identityTypes.find(({ id }) => id === selectionId);

    const value = {
      name: 'document',
      value: {
        ...values['document'],
        type
      }
    };

    addValue(value);
    return value;
  };

  return (
    <ProfileGrid enableGrid={!isEmptyObject(values)}>
      {isEmptyObject(values) && <Loading />}

      {!isEmptyObject(values) && (
        <React.Fragment>
          <StyledForm colon={false}>
            {state.profileInputs.map(({ name, label, type }) => (
              <React.Fragment key={[name, label].join('|')}>
                {name !== 'document' && (
                  <StyledItem label={label} area={name}>
                    <Input
                      disabled={isSubmitting}
                      name={name}
                      onChange={handleChange}
                      type={type}
                      value={values[name]}
                    />
                  </StyledItem>
                )}
                {name === 'document' && (
                  <React.Fragment>
                    <StyledItem label="Documento" area="document_type">
                      <Select
                        disabled={isSubmitting}
                        loading={loadingIdTypes}
                        onChange={id => handleDocumentSelect(id)}
                        optionLabelProp="label"
                        value={values.document.type.name}
                      >
                        {state.identityTypes.map(({ id, name }) => (
                          <Option key={[id, name].join('|')} value={id}>{name}</Option>
                        ))}
                      </Select>
                    </StyledItem>
                    <StyledItem label="Número" area="document_number">
                      <Input
                        name={name}
                        disabled={isSubmitting}
                        onChange={({ currentTarget }) =>
                          addValue({
                            name,
                            value: {
                              ...values[name],
                              number: currentTarget.value
                            }
                          })
                        }
                        type="number"
                        value={values.document.number}
                      />
                    </StyledItem>
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </StyledForm>

          <UserActions>
            <Button
              loading={isSubmitting}
              onClick={() => onSubmit(values)}
              type="primary"
            >
              Guardar Cambios
            </Button>
          </UserActions>

        </React.Fragment>
      )}
    </ProfileGrid>
  );
};

export default Profile;
