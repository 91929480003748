import React, { useContext } from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import { ListingsStore } from '../../reducers/listings/listingsReducer';
import ACTION from '../../reducers/listings/actionTypes';
import DS from '../../VisualStyles';

export const ICON_TYPES = {
  DUPLICATE: 'copy',
  EDIT: 'edit',
  DELETE: 'delete'
};

const REDUCER_MAP = {
  EDIT: ACTION.EDIT_LISTING,
  DUPLICATE: ACTION.DUPLICATE_EVENT,
  DELETE: ACTION.REMOVE_LISTING
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
  color: ${DS.COLORS.ICON.INTERACTIVE} !important;
  font-size: 18px;
`;

const ActionIconsContainer = ({ data, type }) => {
  const [, dispatch] = useContext(ListingsStore);

  const handleClick = type => {
    dispatch({
      eventCode: data.code,
      listingData: data,
      listingId: `E${data.event.id}`,
      type: REDUCER_MAP[type]
    });
  };

  return <StyledIcon type={ICON_TYPES[type]} onClick={() => handleClick(type)} />;
};

export default ActionIconsContainer;
