import React from "react";
import styled from "styled-components";
import Logo from "../Logo/Logo";
import Menu from "../Menu/Menu";
import ContentWrap from "../ContentWrap/ContentWrap";

const HeaderWrap = styled.div`
  background-color: #fff;
  border-bottom: solid 1px #F3F4F8;
  overflow: hidden;
  position: relative;
  z-index: 100;
`;
const InnerHeader = styled.div`
  align-items: center;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 210px 1fr 110px;
  height: 50px;
`;

const Header = () => (
  <HeaderWrap>
    <ContentWrap noMargin>
      <InnerHeader>
        <Logo />
        <Menu />
      </InnerHeader>
    </ContentWrap>
  </HeaderWrap>
);

export default Header;
