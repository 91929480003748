import { COLOR_PALETTE } from "./colorPalette";

const COLORS = {
  TITLE: {
    PRIMARY: COLOR_PALETTE.BRAND.MAIN['400'],
    SECONDARY: COLOR_PALETTE.BRAND.SECONDARY['500']
  },
  SUBTITLE: {
    PRIMARY: COLOR_PALETTE.BRAND.SECONDARY['800'],
    SECONDARY: COLOR_PALETTE.BRAND.SECONDARY['600']
  },
  ICON: {
    INTERACTIVE: COLOR_PALETTE.BRAND.MAIN['400']
  }
}

export { COLORS }