import React from 'react';
import styled from 'styled-components';
import { Button, Drawer, Divider } from 'antd';
import HeadEvent from '../HeadEvent/HeadEvent';
import DrawerHead from './DrawerHead/DrawerHead';
import { useEffect } from 'react';
import { useContext } from 'react';
import ACTION from '../../reducers/sales/actionTypes';
import { API_ROUTES, API_URL } from '../../config';
import useFetch from '../../hooks/useFetch';
import { SalesStore } from '../../reducers/sales/salesReducer';
import { isEmptyObject } from '../../helpers/helpers';
import DS from '../../VisualStyles';

const STR_CONFIG = {
  DRAWER_TITLE: 'Estado de tu Venta',
  DRAWER_TICKET_INFO_TITLE: 'Información sobre las entradas',
  DRAWER_TICKET_DELIVERY_TITLE: 'Información sobre el envío'
};

const ADDITIONAL_LINKS = {
  DELIVERY_TIME: 'delivery_time',
  PRIMARY_PLATAFORM_URL: 'primary_plataform_url',
  INVOICE_URL: 'invoice_url',
  CREDIT_NOTE_URL: 'credit_note_url'
};

const PillTitle = styled.h3`
  border-radius: 3px;
  border: solid 1px ${DS.COLORS.TITLE.PRIMARY};
  color: ${DS.COLORS.TITLE.PRIMARY};
  display: inline-block;
  font-size: 12px;
  margin-bottom: 16px;
  padding: 2px 5px;
  text-transform: uppercase;
`;
const Subtitle = styled.h4`
  font-size: 14px;
  font-weight: 700;
  color: ${DS.COLORS.SUBTITLE.SECONDARY};
  margin: 0;
`;
const EventInfo = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  min-height: 55px;
  margin-bottom: 30px;
`;
const DataGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
const ActionButtons = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`;
const StyledButton = styled(Button)`
  display: block;
  padding: 0 !important;
`;

const TICKET_TYPES = {
  ETICKET: 'eticket',
  MOBILE: 'mobile',
  PAPER: 'paperticket'
};

const Location = ({ location }) => (
  <div>
    {location.section && (
      <DataGroup>
        <Subtitle>Sector</Subtitle>
        <span>{location.section.name}</span>
      </DataGroup>
    )}
    {location.row && (
      <DataGroup>
        <Subtitle>Fila</Subtitle>
        <span>{location.row}</span>
      </DataGroup>
    )}
  </div>
);

const AsideDrawer = ({ data, flags, isOpen, onClose, onPrint }) => {
  const {
    code,
    delivery_method,
    event,
    location,
    metadata,
    tickets,
    ticket_receive,
    ticket_type
  } = data;

  const [state, dispatch] = useContext(SalesStore);
  const { confirmedStatus, sentStatus, shippingStatus } = flags;

  const [additionalData] = useFetch(state.saleAdditionalDataReq);
  const loadAdditionalData = shippingStatus || sentStatus;

  useEffect(() => {
    if (metadata && metadata.length) {
      const [primaryPlatformLink] = metadata.filter(
        meta => meta.key === ADDITIONAL_LINKS.PRIMARY_PLATAFORM_URL
      );

      const linkValue = primaryPlatformLink && primaryPlatformLink.value;

      dispatch({
        primaryPlatformLink:
          confirmedStatus && linkValue ? primaryPlatformLink.value : null,
        type: ACTION.PRIMARY_PLATFORM_LINK
      });
    }
  }, [state.statusFilter, metadata]);

  useEffect(() => {
    if (loadAdditionalData && code) {
      dispatch({
        req: {
          url: `${API_URL}/${API_ROUTES.SALES}/${code}/shipments?type=last`
        },
        type: ACTION.SALE_ADDITIONAL_DATA_REQ
      });
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (!isEmptyObject(additionalData)) {
      const [item] = additionalData;

      dispatch({
        additionalData: item,
        type: ACTION.SALE_ADDITIONAL_DATA
      });
    }
    // eslint-disable-next-line
  }, [additionalData]);

  return (
    <Drawer
      closable
      onClose={onClose}
      title={<DrawerHead code={code} />}
      visible={isOpen}
      width={520}
    >
      <PillTitle>{STR_CONFIG.DRAWER_TICKET_INFO_TITLE}</PillTitle>
      <HeadEvent event={event} spacing />

      <EventInfo>
        <DataGroup>
          <Subtitle>Cantidad</Subtitle>
          <span>{`${tickets} ${tickets === 1 ? 'entrada' : 'entradas'}`}</span>
        </DataGroup>

        {location && (
          <DataGroup>
            <Subtitle>Ubicación</Subtitle>
            <span>{location.name}</span>
          </DataGroup>
        )}

        {ticket_receive && (
          <DataGroup>
            <Subtitle>Importe a recibir</Subtitle>
            <span>{ticket_receive.display}</span>
          </DataGroup>
        )}

        {location && (location.section || location.row) && (
          <Location location={location} />
        )}
      </EventInfo>

      {delivery_method && (
        <React.Fragment>
          <PillTitle>{STR_CONFIG.DRAWER_TICKET_DELIVERY_TITLE}</PillTitle>
          <EventInfo>
            <DataGroup>
              <Subtitle>Método de entrega</Subtitle>
              {ticket_type && (
                <React.Fragment>
                  {ticket_type === TICKET_TYPES.ETICKET && (
                    <span>Entrada electrónica</span>
                  )}
                  {ticket_type === TICKET_TYPES.PAPER && delivery_method.type === 'post'
                    ? 'Envío a domicilio'
                    : 'Retiro en punto de entrega'}
                  {ticket_type === TICKET_TYPES.MOBILE && (
                    <span>Entradas en dispositivo móvil</span>
                  )}
                </React.Fragment>
              )}
            </DataGroup>

            {sentStatus && (
              <React.Fragment>
                <DataGroup>
                  <Subtitle>Empresa de envío</Subtitle>
                  <span>{delivery_method.name}</span>
                </DataGroup>

                {state.saleAdditionalData && state.saleAdditionalData.tracking_number && (
                  <div>
                    <Subtitle>Número de seguimiento</Subtitle>

                    {state.saleAdditionalData.tracking_url && (
                      <StyledButton
                        onClick={() => window.open(state.saleAdditionalData.tracking_url)}
                        type="link"
                      >
                        {state.saleAdditionalData.tracking_number}
                      </StyledButton>
                    )}

                    {!state.saleAdditionalData.tracking_url && (
                      <span>{state.saleAdditionalData.tracking_number}</span>
                    )}
                  </div>
                )}
              </React.Fragment>
            )}
          </EventInfo>
        </React.Fragment>
      )}
      <Divider />

      <ActionButtons>
        <React.Fragment>
          {confirmedStatus && (
            <StyledButton onClick={() => onPrint(code)} icon="barcode" type="link">
              Generar etiquetas
            </StyledButton>
          )}

          {shippingStatus && (
            <StyledButton
              disabled={!state.saleAdditionalData}
              icon="tags"
              onClick={() => window.open(state.saleAdditionalData.label_url, '_blank')}
              type="link"
            >
              Descargar Etiquetas
            </StyledButton>
          )}

          {confirmedStatus && state.primaryPlatformLink && (
            <StyledButton
              icon="monitor"
              onClick={() => window.open(state.primaryPlatformLink, '_blank')}
              type="link"
            >
              Ver en mercado primario
            </StyledButton>
          )}
        </React.Fragment>
      </ActionButtons>
    </Drawer>
  );
};

export default AsideDrawer;
