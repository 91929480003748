import React, { useContext } from 'react';
import { Drawer } from 'antd';
import { PublicationStore } from '../../reducers/publication/publicationReducer';
import PublicationForm from '../PublicationForm/PublicationForm';

const STR_DATA = {
  MODAL_TITLE: 'Editar Publicación'
};

const EditPublication = ({ form, isOpen, listing, loading, onClose, onSubmit }) => {
  const [state] = useContext(PublicationStore);

  const { eventConstraints } = state;

  return (
    <Drawer
      maskClosable
      onClose={onClose}
      title={listing.code && `${STR_DATA.MODAL_TITLE} - ${listing.code}`}
      visible={isOpen}
      width={520}
    >
      <PublicationForm
        constraints={eventConstraints}
        form={form}
        loading={loading}
        onClose={onClose}
        onFormSubmit={onSubmit}
        isEditForm
      />
    </Drawer>
  );
};

export default EditPublication;
