const ACTION = {
  GET_BANK_TYPES_REQ: 'GET_BANK_TYPES_REQ',
  GET_BANK_ACCOUNT_TYPES_REQ: 'GET_BANK_ACCOUNT_TYPES_REQ',
  USER_PAYOUT_DATA_REQ: 'USER_PAYOUT_DATA_REQ',
  GET_USER_DATA_REQ: 'GET_USER_DATA_REQ',
  GET_IDENTITY_TYPES_REQ: 'GET_IDENTITY_TYPES_REQ',
  SUBMIT_PROFILE_REQ: 'SUBMIT_PROFILE_REQ',
  BANK_TYPES: 'BANK_TYPES',
  BANK_ACCOUNT_TYPES: 'BANK_ACCOUNT_TYPES',
  USER_PAYOUT_DATA: 'USER_PAYOUT_DATA',
  IDENTITY_TYPES: 'IDENTITY_TYPES',
  USER_DATA: 'USER_DATA',
  SELECTED_OPTION: 'SELECTED_OPTION',
  PROFILE_INPUTS: 'PROFILE_INPUTS'
};

export default ACTION;