import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';

export const DATE_PARSER = {
  ABBREVIATED: 'd/M/YYYY',
  DATE_TIME: 'D [de] MMMM, HH:mm[hs]',
  DATE_YEAR_TIME: 'D [de] MMMM YYYY, HH:mm[hs]',
  LONG_DATE: 'dddd D [de] MMMM',
  LONG_DATE_TIME: 'dddd D [de] MMMM, HH:mm[hs]',
  DATE: 'ddd DD',
  MONTH: 'MMMM',
  TIME: 'HH:mm[hs]'
};

export const encodeParamsAsURI = (params = {}) =>
  Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

export const formatDate = ({ date, style, lang = 'es-AR' }) => {
  const language = lang && lang.split('-').shift();
  const locales = { es, pt };

  return format(date, style, {
    locale: locales[language]
  });
};

export const removeLettersFromId = code => Number(code.replace(/\D/g, ''));

export const isEmptyObject = obj => Object.entries(obj).length === 0 && obj.constructor === Object

export const fixDecimals = (number, point) => {
  return Math.round(number * `1e${point}`) / `1e${point}`;
}

export const splize = (arr, index, ...newItems) => [
  ...arr.slice(0, index),
  ...newItems,
  ...arr.slice(index)
];

export const getTimestamp = () => Math.round((new Date()).getTime() / 100);