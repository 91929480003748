import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import * as jwt_decode from 'jwt-decode';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [userCountry, setUserCountry] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(true);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  // Custom stuff below

  const ROLE_KEYS = {
    PERMISSIONS: 'http://entradafan.com/claims/permissions',
    ROLES: 'http://entradafan.com/claims/roles'
  };

  const BETA_ROLES = {
    ADMIN: 'BETA_ADMIN',
    USER: 'BETA_USER'
  };

  const userRoles = user && user[ROLE_KEYS.ROLES];

  const isBetaUser = BETA_ROLES.USER.includes(userRoles);
  const isBetaAdmin = BETA_ROLES.ADMIN.includes(userRoles);

  const userFlags = {
    isBetaAdmin,
    isBetaUser
  };

  useEffect(() => {
    if (auth0Client) {
      const getUserCountry = async () => {
        const token =
          'getTokenSilently' in auth0Client && (await auth0Client.getTokenSilently());
        const decodedToken = jwt_decode(token);
        const countryCode = decodedToken['http://entradafan.com/country_code'];

        setUserCountry(countryCode);
      };

      getUserCountry();
    }
  }, [auth0Client]);

  useEffect(() => {
    setUser(user => ({ ...user, country: userCountry }));
  }, [userCountry])

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        userFlags,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
