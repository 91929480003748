const ACTION = {
  COLLAPSED_EVENT_LISTINGS: 'COLLAPSED_EVENT_LISTINGS',
  COMPARE_ACTIVE: 'COMPARE_ACTIVE',
  COMPARE_LISTING_REQ: 'COMPARE_LISTING_REQ',
  COMPARE_LISTING_DATA: 'COMPARE_LISTING_DATA',
  UPDATE_LISTING_GROUP_STATUS: 'UPDATE_LISTING_GROUP_STATUS',
  PAUSE_LISTING_GROUP: 'PAUSE_LISTING_GROUP',
  RESUME_LISTING_GROUP: 'RESUME_LISTING_GROUP',
  REQ_PAUSE_LISTING: 'REQ_PAUSE_LISTING',
  REQ_RESUME_LISTING: 'REQ_RESUME_LISTING',
  EDIT_LISTING: 'EDIT_LISTING',
  REMOVE_LISTING: 'REMOVE_LISTING',
  RESET_EDIT_LISTING: 'RESET_EDIT_LISTING',
  DUPLICATE_EVENT: 'DUPLICATE_EVENT',
  UPDATE_DUPLICATE_EVENT_ID: 'UPDATE_DUPLICATE_EVENT_ID',
  OPEN_PUBLICATION_DRAWER: 'OPEN_PUBLICATION_DRAWER',
  SET_EVENT_LISTINGS: 'SET_EVENT_LISTINGS',
  SET_LISTING_CONSTRAINTS: 'SET_LISTING_CONSTRAINTS',
  ADD_NEW_LISTING: 'ADD_NEW_LISTING',
  UPDATE_LISTING: 'UPDATE_LISTING',
  UPDATE_LISTING_PRICE_REQ: 'UPDATE_LISTING_PRICE_REQ'
};

export default ACTION;
